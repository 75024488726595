import { useForm } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

interface Props {
  existingEmailVerificationCode?: string
  onSubmit: (data: { emailVerificationCode: string }) => void
  resendCode: () => void
  setUpdateEmail: (updateEmail: boolean) => void
}

export const VerifyEmailFormView = ({ existingEmailVerificationCode = '', onSubmit, resendCode, setUpdateEmail }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    trigger
  } = useForm<{ emailVerificationCode: string }>({
    defaultValues: {
      emailVerificationCode: existingEmailVerificationCode
    },
    mode: 'onBlur'
  })

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setValue('emailVerificationCode', value)
    if (value.length === 6) {
      const valid = await trigger('emailVerificationCode')
      if (valid) {
        handleSubmit(onSubmit)()
      }
    }
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="6" htmlFor="emailVerificationCode" className="fw-bold text-end">
            Verification Code:
          </Form.Label>
          <Col sm="4">
            <Form.Control
              id="emailVerificationCode"
              type="text"
              isInvalid={!!errors.emailVerificationCode}
              {...register('emailVerificationCode', {
                pattern: { value: /^[0-9]{6}$/, message: 'Invalid e-mail verification code' }
              })}
              onChange={handleChange}
            />
            <Form.Control.Feedback type="invalid">{errors.emailVerificationCode?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Row className="justify-content-center">
          <Col sm="4" className="text-center">
            <Button onClick={resendCode}>Re-send Code</Button>
          </Col>
          <Col sm="4" className="text-center">
            <Button onClick={() => setUpdateEmail(true)}>Change E-mail Address</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
