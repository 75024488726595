import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Permission, PermissionPatch } from '../../../models/Permission'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: Permission[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

interface IPatchParams {
  id: string
  data: PermissionPatch
  params?: Params
}

export const findAsync = createAsyncThunk('permissions/find', async (params: Params | undefined, { dispatch }): Promise<Permission[]> => {
  try {
    const { data } = await Api.find('permissions', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting permissions: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('permissions/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Permission> => {
  try {
    return await Api.patch('permissions', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

const handleFulfilled = (state: DataState, action: PayloadAction<Permission | Permission[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Permission | Permission[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<Permission | Permission[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded, dataRemoved } = permissionsSlice.actions

export default permissionsSlice.reducer
