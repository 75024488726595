import { Layout } from '../../components/Layout'
import { SupportContactLinkView as SupportContactLink } from '../../components/SupportContactLink'
import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const AcceptableUsePolicyView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Acceptable Use Policy</PageHeader>

      <p>
        This Acceptable Use Policy outlines the rules and responsibilities that you, as a user, agree to adhere to when using the RT Ninja service. Our goal is
        to provide a safe and productive environment for all users.
      </p>

      <h2>Prohibited Actions</h2>
      <p>You are prohibited from engaging in the following activities on our platform:</p>
      <ul>
        <li>Using the service for any unlawful purposes or in violation of any applicable laws or regulations.</li>
        <li>
          Attempting to disrupt the normal flow of dialogue, causing a screen to "scroll" faster than other users are able to type, or otherwise act in a manner
          that negatively affects other users' ability to engage in real-time exchanges.
        </li>
        <li>
          Accessing, or attempting to access, the accounts of other users or penetrating, or attempting to penetrate, security measures of our or another
          entity's computer software or hardware, electronic communications system, or telecommunications system.
        </li>
        <li>
          Engaging in any activities that damage, disable, overburden, or impair any of our server infrastructure or interfere with any other party’s use and
          enjoyment of the service.
        </li>
        <li>Using the service to send, knowingly receive, upload, download, use, or re-use any material which does not comply with our content standards.</li>
        <li>
          Using the service to transmit, or procure the sending of, any unsolicited or unauthorized advertising or promotional material or any other form of
          similar solicitation (spam).
        </li>
        <li>
          Using the service to knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke
          loggers, spyware, adware, or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software
          or hardware.
        </li>
      </ul>

      <h2>Content Standards</h2>
      <p>
        These content standards apply to any and all material which you contribute to the service (‘Contributions’), and to any interactive services associated
        with it. You must comply with the spirit and the letter of the following standards. The standards apply to each part of any Contribution as well as to
        its whole.
      </p>
      <ul>
        <li>The Contribution must be accurate (where it states facts).</li>
        <li>The Contribution must be genuinely held (where it states opinions).</li>
        <li>The Contribution must comply with the law applicable in any country from which it is posted.</li>
        <li>The Contribution must not contain any material which is defamatory, obscene, offensive, hateful, or inflammatory.</li>
        <li>
          The Contribution must not promote sexually explicit material, violence, or discrimination based on race, sex, religion, nationality, disability,
          sexual orientation, or age.
        </li>
        <li>The Contribution must not infringe any copyright, trademark, database right, or other proprietary rights of any other person.</li>
        <li>The Contribution must not be likely to deceive any person.</li>
        <li>The Contribution must not be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
        <li>The Contribution must not be threatening, abusive, or invade another’s privacy, or cause annoyance, inconvenience, or needless anxiety.</li>
        <li>The Contribution must not be used to impersonate any person, or to misrepresent your identity or affiliation with any person.</li>
        <li>The Contribution must not give the impression that it emanates from us, if this is not the case.</li>
        <li>The Contribution must not advocate, promote, or assist any unlawful act.</li>
      </ul>

      <p>
        We reserve the right to terminate or restrict your access to our service if you are found to be in violation of this Acceptable Use Policy. This may
        include legal action, if necessary, for recovery of any losses incurred by us or others. If you have any questions or concerns please{' '}
        <SupportContactLink />.
      </p>

      <p className="text-end">Last updated: May 9th, 2024</p>
    </Layout>
  )
}
