import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import {
  selectBotsLoading,
  selectGuildData,
  selectGuildsLoading,
  selectReactionRolesDataForGuild,
  selectReactionRolesLoading
} from '../../../../redux/selectors'
import { findAsync as findReactionRolesAsync } from '../../../../redux/features/reactionRoles'

import { isAuthorized } from '../../../../lib/authorize'
import { PermissionNames } from '../../../../lib/permissions'

import { ReactionRolesView } from './ReactionRolesView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectReactionRolesLoading(state)
  const canCreateReactionRoles = isAuthorized(PermissionNames.MANAGE_REACTION_ROLES, ownProps.guildId, loading, state)

  return {
    canCreateReactionRoles,
    guild: selectGuildData(ownProps.guildId, state),
    loading,
    reactionRoles: selectReactionRolesDataForGuild(ownProps.guildId, state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  load: (): void => {
    dispatch(findReactionRolesAsync({ query: { guildId: ownProps.guildId } }))
  }
})

export const ReactionRoles = connect(mapStateToProps, mapDispatchToProps)(ReactionRolesView)
