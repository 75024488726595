import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export const NoPermissionsCard = () => {
  return (
    <Row className="justify-content-center mt-3 text-center">
      <Col md={6} lg={4}>
        <Card>
          <Card.Body>
            You do not have permission to create an integration. You must have a role that has been assigned permission to create twitch integrations. Please
            discuss this with your Discord server administrator.
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
