import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'

import { ChannelPickerView } from '../../../../ChannelPicker'

import type { Channel } from '../../../../../models/Guild'
import type { LeavingMessagePatch } from '../../../../../models/LeavingMessage'

interface LeavingMessageFormProps {
  onSubmit: (data: LeavingMessagePatch) => void

  leavingMessage?: LeavingMessagePatch
  onCancel?: () => void
  channels?: Channel[]
}

export const LeavingMessageFormView: React.FC<LeavingMessageFormProps> = ({ leavingMessage, onSubmit, onCancel, channels }) => {
  const [selectedChannel, setSelectedChannel] = useState<LeavingMessagePatch['channelId']>(leavingMessage?.channelId || '')
  const selectedChannelName = channels?.find((channel) => channel.id === selectedChannel)?.name

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue
  } = useForm<LeavingMessagePatch>({
    defaultValues: leavingMessage || {}
  })

  const handleChannelClick = (channel: { id: string; name: string }) => {
    setSelectedChannel(channel.id)
    setValue('channelId', channel.id, { shouldValidate: true })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" className="fw-bold">
            Channel
          </Form.Label>
          <Col sm="10">
            <ChannelPickerView
              buttonText={selectedChannel ? selectedChannelName : 'Select Channel'}
              channels={channels || []}
              onChannelClick={handleChannelClick}
            />
            <input type="hidden" {...register('channelId', { required: 'Channel is required' })} value={selectedChannel} />
            {errors.channelId && <span className="text-danger">{errors.channelId.message}</span>}
          </Col>
        </Form.Group>

        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="message" className="fw-bold">
            Message
          </Form.Label>
          <Col sm="10">
            <Form.Control id="message" as="textarea" rows={3} isInvalid={!!errors.message} {...register('message', { required: 'Message is required' })} />
            <Form.Control.Feedback type="invalid">{errors.message?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>

        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <Button type="submit" variant="primary">
              Submit
            </Button>
            {onCancel && (
              <Button onClick={onCancel} variant="secondary" className="ms-3">
                Cancel
              </Button>
            )}
          </Col>
        </Row>
      </Container>
    </form>
  )
}
