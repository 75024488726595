import { Layout } from '../../components/Layout'
import { SupportContactLinkView as SupportContactLink } from '../../components/SupportContactLink'
import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const RefundPolicyView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Refund Policy</PageHeader>

      <p>
        All purchases made through RT Ninja are final and non-refundable. As a user, you acknowledge and agree that any payments made for services through our
        platform, utilizing payment processors such as Stripe and PayPal, will not be eligible for a refund under any circumstances.
      </p>

      <h5>7-Day Free Trial</h5>

      <p>
        RT Ninja offers a 7-day free trial for new Discord servers. During this period, you can explore the features of the platform within the limits of your
        selected plan, and you will not be charged until after the trial is complete. If you cancel your subscription within the 7-day trial period, you will
        not be charged. Once the trial ends, your payment method will be charged automatically for the full subscription amount, unless you cancel beforehand.
      </p>

      <p>
        While refunds are not available, we do offer the flexibility to transfer your subscription to another server. If you wish to transfer your subscription,
        please <SupportContactLink text="contact our support team" /> for assistance.
      </p>

      <p>
        We encourage you to thoroughly evaluate our features prior to making a purchase. If you have questions or concerns about our services or your purchase,
        please <SupportContactLink text="contact us" /> for assistance before finalizing your transaction.
      </p>

      <p className="text-end">Last updated: May 9th, 2024</p>
    </Layout>
  )
}
