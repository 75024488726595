import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend, type DefaultLegendContentProps } from 'recharts'

import type { PollOption } from '../../../../../../../models/PollOption'
import type { PollVote } from '../../../../../../../models/PollVote'

const legendColors = ['#1a1efa', '#54513e', '#94ec41', '#c39031', '#222957', '#b13abf', '#9839d5', '#9ac8dd', '#494b1c', '#01fb14']

interface Props {
  pollOptions: PollOption[]
  pollVotes: PollVote[]
}

const renderCustomizedLegend = ({ payload }: DefaultLegendContentProps) => {
  return (
    <>
      <h5 className="text-center">Legend</h5>
      <ul className="list-unstyled">
        {payload?.map((entry, index) => (
          <li key={`${entry.value}-${index}`} title={entry.value}>
            <span
              style={{
                display: 'inline-block',
                height: '12px',
                width: '12px',
                backgroundColor: entry.color,
                marginRight: '5px',
                borderRadius: '50%'
              }}
            />
            {entry.value.length > 80 ? `${entry.value.substring(0, 80)}...` : entry.value}
          </li>
        ))}
      </ul>
    </>
  )
}

export const PieGraphView = ({ pollOptions, pollVotes }: Props) => {
  const data = pollOptions
    .map((pollOption: PollOption, index: number) => {
      return {
        name: pollOption.text,
        value: pollVotes.filter((pollVote: PollVote) => pollVote.optionId === pollOption.id).length,
        color: legendColors[index]
      }
    })
    .filter((item) => {
      return item.value
    })

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie dataKey="value" data={data} cx="46%" cy="50%" outerRadius={140}>
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        <Tooltip />
        <Legend layout="vertical" align="right" verticalAlign="middle" content={renderCustomizedLegend} wrapperStyle={{ width: '40%', marginRight: 'auto' }} />
      </PieChart>
    </ResponsiveContainer>
  )
}
