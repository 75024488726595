import { useForm } from 'react-hook-form'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

import type { BotPatch } from '../../../../../../models/Bot'

interface Props {
  onSubmit: (data: BotPatch) => void
}

export const PersonalizedBotTokenFormView = ({ onSubmit }: Props) => {
  const { handleSubmit, register, watch } = useForm()
  const personalizedBotToken = watch('personalizedBotToken', '')

  return (
    <Card style={{ width: '400px', margin: '10px auto' }}>
      <Card.Body className="text-center">
        <Card.Title>Personalized Bot Token</Card.Title>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Card.Body>
            <Form.Control type="password" {...register('personalizedBotToken')} autoComplete="off" />
          </Card.Body>
          <Card.Footer>
            <Button type="submit" disabled={!personalizedBotToken}>
              Submit
            </Button>
          </Card.Footer>
        </form>
      </Card.Body>
    </Card>
  )
}
