import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'

import { darkThemes, lightThemes, themes } from '../../../../redux/features/theme/themeSlice'

import { capitalizeFirstLetter } from '../../../../lib/util'

import './styles.css'

interface Props {
  themeFilter?: string
  currentTheme: string
  changeTheme: (theme: string) => void
}

export const ThemesView = ({ changeTheme, currentTheme, themeFilter }: Props) => {
  const filteredThemes = themeFilter === 'darkThemes' ? darkThemes : themeFilter == 'lightThemes' ? lightThemes : themes
  const thumbnailClassName = `theme-thumbnail-${darkThemes.find((darkTheme) => darkTheme === currentTheme) ? 'dark' : 'light'}`

  return (
    <Container>
      <Row>
        {filteredThemes.map((theme: string) => {
          return (
            <Col key={theme} md={4} className="text-center mb-5">
              <h6>
                {capitalizeFirstLetter(theme).replace(/Rtninja/, 'RT Ninja')} {currentTheme === theme ? '(Current)' : ''}
              </h6>
              <Image className={thumbnailClassName} src={`/themes/thumbnails/${theme}.png`} style={{ width: '80%' }} onClick={() => changeTheme(theme)} />
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}
