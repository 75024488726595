import { useParams } from 'react-router-dom'

import { Layout } from '../../../../../components/Layout'
import { AddPoll } from '../../../../../components/Guilds/GuildDetail/Polls/Add'

export const AddPollView = () => {
  const { channelId, guildId } = useParams()

  return (
    <Layout>
      <AddPoll guildId={guildId!} channelId={channelId!} />
    </Layout>
  )
}
