import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import {
  selectBotsLoading,
  selectGuildData,
  selectGuildsLoading,
  selectSubscriptionsDataForGuild,
  selectSubscriptionsErrorMessage,
  selectSubscriptionsLoading,
  selectSubscriptionModifying
} from '../../../../redux/selectors'
import { createAsync as createSubscriptionAsync, patchAsync as patchSubscriptionAsync } from '../../../../redux/features/subscriptions/subscriptionsSlice'

import type { SubscriptionData } from '../../../../models/Subscription'

import { StripeView } from './StripeView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectSubscriptionsLoading(state)

  return {
    guild: selectGuildData(ownProps.guildId!, state),
    loading,
    subscription: selectSubscriptionsDataForGuild(ownProps.guildId!, state),
    subscriptionModifying: selectSubscriptionModifying(state),
    subscriptionErrorMessage: selectSubscriptionsErrorMessage(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  createSubscription: (plan: string, planDuration: string) =>
    dispatch(createSubscriptionAsync({ data: { guildId: ownProps.guildId, plan, planDuration, provider: 'stripe' } as unknown as SubscriptionData })),
  previewUpdateSubscription: (id: string, plan: string, planDuration: string) =>
    dispatch(patchSubscriptionAsync({ id, data: { plan, planDuration }, params: { query: { preview: true } } })),
  updateSubscription: async (id: string, plan: string, planDuration: string) => {
    await dispatch(patchSubscriptionAsync({ id, data: { plan, planDuration } }))
  }
})

export const Stripe = connect(mapStateToProps, mapDispatchToProps)(StripeView)
