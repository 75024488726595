import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export const NoSubscriptionCard = ({ guildId }: { guildId: string }) => {
  const navigate = useNavigate()

  return (
    <Row className="justify-content-center mt-3 text-center">
      <Col md={6} lg={4}>
        <Card>
          <Card.Body>A subscription is required to create an integration with Twitch.</Card.Body>
          <Button onClick={() => navigate(`/subscription/${guildId}`)}>Subscribe</Button>
        </Card>
      </Col>
    </Row>
  )
}
