import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import type { Guild } from '../../models/Guild'

import { Loading } from '../Loading'
import { GuildCardView as GuildCard } from './components/GuildCardView'

interface Props {
  guilds: Guild[]
  loading: boolean
}

export const GuildsView = ({ guilds, loading }: Props) => {
  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : (
    <Container>
      <Row>
        {guilds.map((guild: Guild) => {
          return (
            <Col md={3} key={guild.id} className="mb-4">
              <GuildCard guild={guild} />
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}
