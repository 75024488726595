import Accordion from 'react-bootstrap/Accordion'

export const Commands = () => {
  return (
    <Accordion>
      <Accordion.Item eventKey="0">
        <Accordion.Header>/roll - Simulate a dice roll</Accordion.Header>
        <Accordion.Body>
          <h5>Command Usage</h5>
          <p>Simulate rolling one or more dice with customizable options.</p>
          <h6>Basic Usage</h6>
          <pre>
            <code>/roll</code>
          </pre>
          <p>This command rolls a single 6-sided die.</p>

          <h6>Advanced Usage</h6>
          <pre>
            <code>/roll max:10 count:3</code>
          </pre>
          <p>This command rolls three 10-sided dice.</p>

          <h6>Options</h6>
          <ul>
            <li>
              <strong>max</strong>: Set the maximum value for the dice (default is 6). Accepts a format of "number+number" or "number-number", e.g., "10+3" or
              "10-2".
              <ul>
                <li>
                  Example: <code>/roll max:10</code> rolls a 10-sided die.
                </li>
                <li>
                  Example: <code>/roll max:10+2</code> rolls a 10-sided die with a +2 modifier.
                </li>
                <li>
                  Example: <code>/roll max:10-2</code> rolls a 10-sided die with a -2 modifier.
                </li>
              </ul>
            </li>
            <li>
              <strong>count</strong>: Set the number of dice to roll (default is 1, max is 10).
              <ul>
                <li>
                  Example: <code>/roll count:3</code> rolls three dice.
                </li>
              </ul>
            </li>
          </ul>

          <h6>Error Handling</h6>
          <p>The command will return an error message if any of the inputs are invalid:</p>
          <ul>
            <li>
              <strong>Max value</strong>: Must be in the format of "number+number" or "number-number", e.g., "10+3" or "10-2".
            </li>
            <li>
              <strong>Maximum number</strong>: Cannot exceed 1,000,000.
            </li>
            <li>
              <strong>Modifier</strong>: Positive modifiers must be 100 or below; negative modifiers must be -100 or greater.
            </li>
            <li>
              <strong>Count</strong>: Must be 10 or below.
            </li>
          </ul>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>/poll - Create a poll in the current channel</Accordion.Header>
        <Accordion.Body>
          <h5>Command Usage</h5>
          <p>Create a poll in the current channel with a link to the dashboard.</p>
          <h6>Basic Usage</h6>
          <pre>
            <code>/poll</code>
          </pre>
          <p>This command provides a link to the dashboard where you can create a poll.</p>

          <h6>Details</h6>
          <p>
            When you use the <code>/poll</code> command, you will receive a link to the dashboard where you can create your poll. Only users with the required
            permissions can create polls.
          </p>

          <h6>Voting</h6>
          <p>
            Anyone can vote on the poll unless the channel permissions are set otherwise. If you want only certain users to vote, create a channel with
            restricted access and create the poll in that channel.
          </p>

          <h6>Error Handling</h6>
          <p>
            The command will return an error message if you do not have the required permissions to create polls. By default all users have permission to create
            a poll in a channel they have access to.
          </p>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
}
