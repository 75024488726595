import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

interface Props {
  guildId: string
  isTrial: boolean
}

export const PaymentCompleteView = ({ guildId, isTrial }: Props) => {
  const navigate = useNavigate()

  const handleGoToServerClick = () => {
    navigate(`/dashboard/${guildId}`)
  }

  return (
    <>
      <Row className="justify-content-center my-3">
        <Col md={6} lg={6} className="text-center">
          <h2>{isTrial ? 'Payment Method Setup' : 'Payment'} Complete</h2>
        </Col>
      </Row>
      <Row className="justify-content-center mb-4">
        <Col md={6} lg={6} className="text-center">
          <h1>Thank you!</h1>
        </Col>
      </Row>
      <Row className="justify-content-center mb-3">
        <Col md={6} lg={6} className="text-center">
          <h3>
            {isTrial
              ? 'Your subscription will be active once your payment method setup has finished processing.'
              : 'Your subscription will be active once your payment has finished processing.'}
          </h3>
        </Col>
      </Row>
      <Row className="justify-content-center mb-4">
        <Col md={6} lg={6} className="text-center">
          <Button className="fs-2 py-2 px-5 mx-auto" onClick={handleGoToServerClick}>
            Go to Server
          </Button>
        </Col>
      </Row>
    </>
  )
}
