import { createRoot } from 'react-dom/client'
import * as Sentry from '@sentry/react'
import { captureConsoleIntegration } from '@sentry/browser'
import App from './App'
import store from './redux/store'
import { loginAsync } from './redux/features/users/usersSlice'
import client from './lib/feathers/feathersClient'
import { environment } from './lib/feathers/config'
import { SyncManager } from './lib/feathers/lib/syncManager'

import 'bootstrap-icons/font/bootstrap-icons.css'
import 'react-datepicker/dist/react-datepicker.css'

const ignoreErrorsRegexp = /(jwt expired|No accessToken found in storage)/i

Sentry.init({
  environment,
  dsn: 'https://d76b40909ebd556051c738584f0e951e@o4506482562760704.ingest.sentry.io/4506482605162496',
  tracePropagationTargets: ['https://denisonweb-local-development.com/', 'https://staging.rt.ninja/', 'https://rt.ninja/'],
  integrations: [
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false
    }),
    captureConsoleIntegration({
      levels: ['error']
    })
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  beforeSend(event) {
    const hostname = window.location.hostname
    if (ignoreErrorsRegexp.test(event.exception?.values?.[0].value || '') || hostname === 'denisonweb-local-development.com' || hostname === 'localhost') {
      return null
    }

    return event
  }
})

if (window.localStorage.getItem('feathers-jwt') || window.localStorage.getItem('feathers-rt')) {
  store.dispatch(loginAsync())
}

client.on('login', () => {
  SyncManager.init()
})

const createThemeStylesheetLink = (theme: string): void => {
  const link = document.createElement('link')
  link.id = 'theme-stylesheet'
  link.setAttribute('rel', 'stylesheet')
  link.setAttribute('href', `/themes/${theme}.bootstrap.min.css`)
  document.head.appendChild(link)
}

createThemeStylesheetLink(window.localStorage?.getItem('currentTheme')!)

const root = createRoot(document.getElementById('root') as HTMLElement)

root.render(<App />)
