import { Layout } from '../../components/Layout'
import { SupportContactLinkView as SupportContactLink } from '../../components/SupportContactLink'

import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const SecurityPolicyView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Security Policy</PageHeader>

      <p>
        At RT Ninja, we prioritize the security of our users' data and our application infrastructure. We are committed to implementing robust security measures
        that meet or exceed industry standards to ensure the protection of all data managed by our services, both at rest and in transit.
      </p>

      <h5>Data Protection</h5>

      <p>
        We utilize advanced encryption methods to secure data at rest and in transit. For data at rest, we employ encryption algorithms that are widely
        recognized as industry standards, ensuring that stored data is inaccessible without proper authorization. For data in transit, we use secure protocols
        such as TLS (Transport Layer Security) to encrypt the data exchanged between our servers and your devices.
      </p>

      <h5>Access Control</h5>

      <p>
        Access to our systems is strictly controlled using a combination of physical and electronic measures. We implement robust authentication mechanisms to
        ensure that only authorized personnel have access to sensitive data and system functionalities. Monitoring is conducted to ensure compliance with our
        security policies and to detect any potential security breaches.
      </p>

      <h5>Incident Response</h5>

      <p>
        In the event of a security breach, we have a formal incident response plan designed to promptly address and mitigate any potential damage. This plan
        includes immediate investigation, notification to affected users, and remedial actions to prevent future occurrences.
      </p>

      <p>
        We continuously update our security practices and infrastructure to address emerging threats and vulnerabilities. Our commitment to security is integral
        to our mission, ensuring that our users can trust RT Ninja with their critical data and operations. If you have any questions or concerns, please{' '}
        <SupportContactLink text="contact our support team" />.
      </p>

      <p className="text-end">Last updated: May 9th, 2024</p>
    </Layout>
  )
}
