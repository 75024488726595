import Card from 'react-bootstrap/Card'

import { capitalizeFirstLetter, formatDate } from '../../../lib/util'

interface Props {
  expiresDate: Date
  plan: string
  planDurationInMonths: number
  total: string
}

export const SubscriptionDetailsView = ({ expiresDate, plan, planDurationInMonths, total }: Props) => {
  return (
    <Card>
      <Card.Header>Subscription Details</Card.Header>
      <Card.Body>
        <h4>Plan: {capitalizeFirstLetter(plan)}</h4>
        <h4>
          Duration: {planDurationInMonths} month{planDurationInMonths === 1 ? '' : 's'}
        </h4>
        <h4>Expires on: {formatDate(expiresDate.toISOString(), 'MMMM d, yyyy')}</h4>
        {total ? (
          <Card.Footer className="mt-4">
            <h2>Total: ${total}</h2>
          </Card.Footer>
        ) : null}
      </Card.Body>
    </Card>
  )
}
