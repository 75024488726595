import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import {
  selectBotsLoading,
  selectBotDataByGuildId,
  selectCurrentUser,
  selectGuildData,
  selectGuildsLoading,
  selectPermissionsLoading,
  selectSubscriptionsDataForGuild,
  selectTwitchIntegrationsData,
  selectTwitchIntegrationsLoading
} from '../../../../redux/selectors'

import { Api } from '../../../../lib/api'
import { isAuthorized } from '../../../../lib/authorize'
import { PermissionNames } from '../../../../lib/permissions'

import { findAsync } from '../../../../redux/features/twitchIntegrations'

import { TwitchIntegrationView } from './TwitchIntegrationView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state) || selectTwitchIntegrationsLoading(state)

  const canCreate = isAuthorized(PermissionNames.MANAGE_TWITCH_INTEGRATIONS, ownProps.guildId, loading, state)

  return {
    bot: selectBotDataByGuildId(ownProps.guildId, state),
    canCreate,
    currentUser: selectCurrentUser(state),
    guild: selectGuildData(ownProps.guildId, state),
    subscription: selectSubscriptionsDataForGuild(ownProps.guildId, state),
    loading,
    twitchIntegration: selectTwitchIntegrationsData(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  load: (discordUserId: string) => {
    dispatch(findAsync({ query: { discordUserId, guildId: ownProps.guildId } }))
  },
  startOauthFlow: () => {
    Api.handleOAuthIntegration(ownProps.guildId, 'twitch')
  }
})

export const TwitchIntegration = connect(mapStateToProps, mapDispatchToProps)(TwitchIntegrationView)
