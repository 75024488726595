import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import type { Guild } from '../../../models/Guild'

import { openDiscordBotOauthUrl } from '../../../lib/util'
import { SyncManager } from '../../../lib/feathers/lib/syncManager'

interface Props {
  guild?: Guild
}

export const NoBotInGuildView = ({ guild }: Props) => {
  const addBot = (): void => {
    SyncManager.setDiscordMainBotOauthWindowByGuildId(guild!.id, openDiscordBotOauthUrl(guild!.id))
  }

  return (
    <Container>
      {guild ? (
        <>
          <h2 className="text-center mt-5 mb-3">There is currently no bot in {guild?.name}</h2>
          <div className="text-center">
            <Button onClick={addBot}>Add Bot</Button>
          </div>
        </>
      ) : (
        <h2 className="text-center mt-5 mb-3">There is currently no bot in this server and you do not have permission to add one</h2>
      )}
    </Container>
  )
}
