import { connect } from 'react-redux'

import type { RootState } from '../../redux/store'
import { selectBotsLoading, selectGuildData, selectGuildsData, selectGuildsLoading } from '../../redux/selectors'

import { SubscriptionView } from './SubscriptionView'

interface OwnProps {
  guildId?: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state)

  return {
    guild: ownProps?.guildId ? selectGuildData(ownProps.guildId, state) : undefined,
    guilds: selectGuildsData(state).filter((guild: { hasBot: boolean }) => guild.hasBot),
    loading
  }
}

export const Subscription = connect(mapStateToProps)(SubscriptionView)
