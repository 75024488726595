import { useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'

import { camelToDashCase, dashToCamelCase } from '../../../../lib/util'

import type { Guild } from '../../../../models/Guild'
import type { Permission, PermissionPatch } from '../../../../models/Permission'

import { Loading } from '../../../Loading/Loading'
import { PageHeaderView as PageHeader } from '../../../PageHeader'

interface Props {
  canEditPermissions: boolean
  currentThemeType: string
  guild?: Guild
  loading: boolean
  permissions: Permission[]
  updatePermission: (id: string, data: PermissionPatch) => void
}

const fullNameByPermissionName: Record<string, string> = {
  editPermissions: 'Edit Permissions',
  createPolls: 'Create Polls',
  closeOtherPolls: "Close Other Members' Polls",
  removeOtherPolls: "Remove Other Members' Polls",
  createReactionRoles: 'Create Reaction Roles',
  createTiktokIntegrations: 'Create TikTok Integrations',
  createTwitchIntegrations: 'Create Twitch Integrations',
  createYoutubeIntegrations: 'Create YouTube Integrations',
  manageReactionRoles: 'Manage Reaction Roles',
  updatePersonalizedBotData: 'Update Personalized Bot Data'
}

const descriptionByPermissionName: Record<string, string> = {
  editPermissions:
    'Allows editing of permissions. Server owners always have this right. By default, roles with admin or manage server permissions can also edit these.',
  createPolls: 'Allows roles to create new polls. All roles are permitted to do this by default.',
  closeOtherPolls: 'Allows roles to close polls created by others. Defaults to roles with admin or manage server permissions.',
  removeOtherPolls: 'Allows roles to remove polls created by others. Defaults to roles with admin or manage server permissions.',
  createReactionRoles: 'Allows roles to create reaction roles for messages.',
  createTiktokIntegrations: 'Allows roles to integrate with TikTok.',
  createTwitchIntegrations: 'Allows roles to integrate with Twitch.',
  createYoutubeIntegrations: 'Allows roles to integrate with YouTube.',
  manageReactionRoles: 'Allows roles to manage existing reaction roles.',
  updatePersonalizedBotData: 'Allows roles to update personalized data for the bot.'
}

export const PermissionsView = ({ canEditPermissions, currentThemeType, guild, loading, permissions, updatePermission }: Props) => {
  const navigate = useNavigate()
  const { viewMode: unfilteredViewMode } = useParams()
  const viewMode = unfilteredViewMode ? unfilteredViewMode : 'edit-permissions'
  const currentPermission = permissions.find((permission) => permission.name === dashToCamelCase(viewMode))
  const assignableRolesForPermission = guild?.roles
    ?.filter((role) => {
      if (role.managed) {
        return false
      }

      if (viewMode === 'edit-permissions') {
        return role.isAdmin || role.isManage
      }

      return true
    })
    .sort((a, b) => a.name.localeCompare(b.name))

  const handleAddRoleToPermission = (roleId: string) => {
    const roles = currentPermission!.roles.concat([roleId])
    updatePermission(currentPermission!.id, { roles })
  }

  const handleRemoveRoleFromPermission = (roleId: string) => {
    const roles = currentPermission!.roles.filter((role) => role !== roleId)
    updatePermission(currentPermission!.id, { roles })
  }

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !canEditPermissions ? (
    <div>You do not have permission to edit permissions</div>
  ) : !viewMode || !currentPermission ? (
    <div>Permission not found</div>
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>Server Permissions</PageHeader>
      <Row className="text-center mb-4">
        <Col>
          <Dropdown>
            <Dropdown.Toggle variant="info">{fullNameByPermissionName[currentPermission.name]}</Dropdown.Toggle>
            <Dropdown.Menu>
              {permissions.map((permission) => (
                <Dropdown.Item key={permission.id} onClick={() => navigate(`/dashboard/${guild?.id}/permissions/${camelToDashCase(permission.name)}`)}>
                  {fullNameByPermissionName[permission.name]}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h3 className="text-center">{fullNameByPermissionName[dashToCamelCase(viewMode || 'editPermissions')]}</h3>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <h5 className="text-center">{descriptionByPermissionName[dashToCamelCase(viewMode || 'editPermissions')]}</h5>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center">
        {!assignableRolesForPermission?.length ? (
          <h5 className="text-center text-danger">There are currently no roles that have either admin or manage server permissions.</h5>
        ) : null}
        {assignableRolesForPermission?.map((role) => {
          return (
            <Col key={role.id} md={3} className="d-flex justify-content-center mb-4">
              <Button
                variant={currentPermission?.roles.indexOf(role.id) !== -1 ? 'success' : 'danger'}
                className="d-flex align-items-center justify-content-center"
                onClick={() => {
                  currentPermission?.roles.indexOf(role.id) !== -1 ? handleRemoveRoleFromPermission(role.id) : handleAddRoleToPermission(role.id)
                }}
              >
                {role.name}
                <i
                  className={`bi bi-${currentPermission?.roles.indexOf(role.id) !== -1 ? 'check' : 'x'}-square ms-3 fs-1`}
                  style={{ color: currentThemeType === 'light' ? 'white' : 'black' }}
                />
              </Button>
            </Col>
          )
        })}
      </Row>
    </Container>
  )
}
