import { Layout } from '../../components/Layout'
import { SupportContactLinkView as SupportContactLink } from '../../components/SupportContactLink'
import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const TermsAndConditionsView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Terms and Conditions</PageHeader>

      <p>
        Welcome to RT Ninja ("we", "our", or "us"), a Discord bot and dashboard service designed to enhance your Discord experience. These Terms and Conditions
        govern your use of our website, services, and any related software (collectively, the "Service").
      </p>

      <h2>Acceptance of Terms</h2>
      <p>
        By accessing or using our Service, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree to these terms,
        please do not use our Service.
      </p>

      <h2>Service Description</h2>
      <p>
        RT Ninja provides tools for managing Discord servers, including providing deep integrations with popular streaming platforms, creating polls, allowing
        users to react to a message to receive a server role, simulating dice rolls, and other interactive activities to enhance community engagement and make
        your life easier as a Discord administrator. Our Service is subject to change and improvement at our discretion.
      </p>

      <h2>User Accounts</h2>
      <p>
        You may need to register an account to access certain features of the Service, which will happen automatically when you log in for the first time.
        Registration requires you to log in using your Discord account and that allows us access to view the servers you are a part of. You agree to provide
        accurate, complete, and updated registration information. You are responsible for maintaining the confidentiality of your account login information and
        are fully responsible for all activities that occur under your account.
      </p>

      <h2>Prohibited Conduct</h2>
      <p>
        You agree not to use the Service for any unlawful purpose or in any way that interrupts, damages, or impairs the service. Prohibited activities include,
        but are not limited to:
      </p>
      <ul>
        <li>Violating any applicable law or regulation</li>
        <li>Transmitting viruses, malware, or other malicious code</li>
        <li>Attempting to gain unauthorized access to the Service or other users' accounts</li>
      </ul>

      <h2>Intellectual Property</h2>
      <p>
        All rights, title, and interest in and to the Service (excluding content provided by users) are and will remain the exclusive property of RT Ninja and
        its licensors.
      </p>

      <h2>Termination</h2>
      <p>
        We may terminate or suspend your access to the Service immediately, without prior notice or liability, for any reason, including but not limited to a
        breach of these Terms.
      </p>

      <h2>Limitation of Liability</h2>
      <p>
        RT Ninja shall not be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits,
        data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.
      </p>

      <h2>Changes to Terms</h2>
      <p>
        We reserve the right to modify these Terms at any time. If we make changes, we will notify you by revising the date at the bottom of the Terms and, in
        some cases, provide additional notice. Your continued use of the Service after such notification constitutes your consent to the changes.
      </p>

      <h2>Contact Us</h2>
      <p>
        If you have any questions about these Terms, please <SupportContactLink />.
      </p>

      <p className="text-end">Last updated: May 9th, 2024</p>
    </Layout>
  )
}
