import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import { isValidWebUrl } from '../../../../../../lib/util'

import type { Bot, BotPatch } from '../../../../../../models/Bot'

const maxAvatarSize = 8 * 1024 * 1024

const botStatusOptions = [
  { value: 'online', label: 'Online' },
  { value: 'idle', label: 'Idle' },
  { value: 'invisible', label: 'Invisible' },
  { value: 'dnd', label: 'Do Not Disturb' }
]

const activityTypeOptions = [
  { value: -1, label: 'None' },
  { value: 0, label: 'Playing' },
  { value: 1, label: 'Streaming' },
  { value: 2, label: 'Listening to' },
  { value: 3, label: 'Watching' },
  { value: 5, label: 'Competing in' }
]

interface Props {
  bot: Bot
  onSubmit: (data: BotPatch) => void
}

export const PersonalizedBotDetailsFormView = ({ bot, onSubmit }: Props) => {
  const {
    formState: { errors },
    handleSubmit,
    register,
    setError,
    setValue,
    watch
  } = useForm<BotPatch>({
    defaultValues: {
      username: bot.username,
      avatarUrl: bot.avatarUrl || '',
      status: bot.status,
      activityType: bot.activityType ?? -1,
      activityStatus: bot.activityStatus || '',
      activityUrl: bot.activityUrl || ''
    }
  })
  const [filePreview, setFilePreview] = useState('')
  const activityType = watch('activityType')

  useEffect(() => {
    if (Number(activityType) === -1) {
      setValue('activityStatus', '', { shouldValidate: true })
    }
    if (Number(activityType) !== 1) {
      setValue('activityUrl', '', { shouldValidate: true })
    }
  }, [activityType, setValue])

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      if (file.size > maxAvatarSize) {
        setError('avatarUrl', {
          type: 'size',
          message: 'File size should not exceed 5MB.'
        })
        event.target.value = ''
        return
      }
      const reader = new FileReader()
      reader.onloadend = () => {
        const base64String = String(reader.result)
        setFilePreview(base64String)
        setValue('avatarUrl', base64String)
      }
      reader.readAsDataURL(file)
    }
  }

  return (
    <Form
      onSubmit={handleSubmit((data) => {
        data.activityType = Number(data.activityType)
        onSubmit(data)
      })}
    >
      <Container>
        <Row>
          <Col md={4}>
            <Form.Group className="mb-3 d-flex flex-column align-items-center justify-content-center">
              <Form.Label>Avatar</Form.Label>
              <img src={filePreview || bot.avatarUrl} alt="Bot Avatar" className="my-3" style={{ width: '160px', height: '160px', borderRadius: '50%' }} />
              <Form.Control type="file" accept=".jpg,.jpeg,.png,.gif,.webp" onChange={handleFileChange} isInvalid={!!errors.avatarUrl} />
              {errors.avatarUrl && <Form.Text className="text-danger">{String(errors.avatarUrl.message)}</Form.Text>}
            </Form.Group>
          </Col>

          <Col md={8}>
            <Form.Group className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" {...register('username', { required: true, maxLength: 128 })} placeholder="Enter bot name" />
              {errors.username && <Form.Text className="text-danger">Name is required and must not be more than 128 characters</Form.Text>}
            </Form.Group>

            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select {...register('status')}>
                    {botStatusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>

              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Activity Type</Form.Label>
                  <Form.Select {...register('activityType')}>
                    {activityTypeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            {Number(activityType) !== -1 ? (
              <Form.Group className="mb-3">
                <Form.Label>Activity Status</Form.Label>
                <Form.Control type="text" {...register('activityStatus', { required: true, maxLength: 128 })} placeholder="Enter activity status" />
                {errors.activityStatus && <Form.Text className="text-danger">Activity Status is required and must not be more than 128 characters</Form.Text>}
              </Form.Group>
            ) : null}

            {Number(activityType) === 1 ? (
              <Form.Group className="mb-3">
                <Form.Label>Streaming Url</Form.Label>
                <Form.Control
                  type="text"
                  {...register('activityUrl', { validate: (input?: string) => isValidWebUrl(input || '', { requireProtocol: true }) })}
                  placeholder="Enter activity status"
                />
                {errors.activityUrl && <Form.Text className="text-danger">Activity URL is required and must be valid</Form.Text>}
              </Form.Group>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={{ offset: 5, span: 2 }} className="text-center">
            <Button variant="primary" type="submit">
              Update Bot
            </Button>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}
