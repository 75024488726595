import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import { calculateMonthlyPrice, type subscriptionData } from '../../../lib/subscription'
import { capitalizeFirstLetter } from '../../../lib/util'

import type { DurationOptionType } from '../shared'

type SubscriptionData = (typeof subscriptionData)[number]

interface Props {
  data: SubscriptionData
  index: number
  selectedCard: number | null
  setSelectedCard: (index: number) => void
  selectedDuration: DurationOptionType
}

export const SubscriptionCardView = ({ data, index, selectedCard, selectedDuration, setSelectedCard }: Props) => {
  return (
    <Card className={`subscription-card ${selectedCard === index ? 'selected-plan' : ''}`}>
      <Card.Header className="text-center fs-2 fw-bold">{capitalizeFirstLetter(data.name)}</Card.Header>
      <Card.Body className="d-flex flex-column justify-content-between">
        <Card.Title className="fs-4">Includes:</Card.Title>
        <ul>
          {data.details.map((detail) => (
            <li key={detail}>{detail}</li>
          ))}
        </ul>
        <div className="text-center mt-auto">
          <div className="price-wrapper mb-3">${calculateMonthlyPrice(data.name, selectedDuration)} / month</div>
          <Button variant={selectedCard === index ? 'success' : 'info'} className="select-button" onClick={() => setSelectedCard(index)}>
            Select
          </Button>
        </div>
      </Card.Body>
    </Card>
  )
}
