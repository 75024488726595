import { connect } from 'react-redux'

import type { RootState } from '../../../../redux/store'
import {
  selectBotsLoading,
  selectCurrentUser,
  selectGuildData,
  selectGuildsLoading,
  selectSubscriptionsDataForGuild,
  selectSubscriptionsLoading
} from '../../../../redux/selectors'

import { PaypalView } from './PaypalView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectSubscriptionsLoading(state)

  return {
    guild: selectGuildData(ownProps.guildId!, state),
    loading,
    subscription: selectSubscriptionsDataForGuild(ownProps.guildId!, state),
    user: selectCurrentUser(state)
  }
}

export const Paypal = connect(mapStateToProps)(PaypalView)
