import type { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

interface Props {
  children: ReactNode

  backButtonPath?: string
  className?: string
  rightButton?: ReactNode
  showBackButton?: boolean
}

export const PageHeaderView = ({ backButtonPath = '/', children, className = 'mb-3', rightButton, showBackButton = true }: Props) => {
  const navigate = useNavigate()

  return (
    <Container className={className}>
      <Row>
        <Col md={1}>{showBackButton ? <Button onClick={() => navigate(`${backButtonPath}`)}>Back</Button> : null}</Col>
        <Col md={10}>
          <h2 className="text-center">{children}</h2>
        </Col>
        {rightButton ? <Col md={1}>{rightButton}</Col> : null}
      </Row>
    </Container>
  )
}
