import { Layout } from '../../components/Layout'
import { SupportContactLinkView as SupportContactLink } from '../../components/SupportContactLink'
import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const CookiePolicyView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Cookie Policy</PageHeader>

      <p>
        This Cookie Policy explains how we, RT Ninja, use cookies and similar technologies to recognize you when you visit our website. It is intended to inform
        you about our use of cookies and your cookie control options.
      </p>

      <h2>What Are Cookies?</h2>

      <p>
        Cookies are small data files placed on your device or computer when you visit a website. Cookies are widely used by website owners to make their
        websites work, as well as to provide reporting information.
      </p>

      <p>
        Cookies set by the website owner (in this case, RT Ninja) are called "first-party cookies." Cookies set by parties other than the website owner are
        called "third-party cookies." Third-party cookies enable third-party features or functionality to be provided on or through the website (e.g., payment
        processing, advertising, interactive content, and analytics).
      </p>

      <h2>Why Do We Use Cookies?</h2>

      <p>
        We use first-party cookies for the initial login. Some cookies are required for technical reasons in order for our website to operate, and we refer to
        these as "essential" or "strictly necessary" cookies. Other cookies can be used to track and target the interests of users and to enhance the experience
        of websites, but **we do not use such cookies** for tracking or advertising purposes.
      </p>

      <h2>Third-Party Cookies for Payment Processing</h2>

      <p>
        We use third-party cookies only in connection with our payment processors (e.g., Stripe, PayPal) to facilitate secure transactions and provide essential
        functionality for completing payments. These cookies help ensure that your payment information is processed securely and efficiently.
      </p>

      <h2>No Tracking or Advertising Cookies</h2>

      <p>
        At RT Ninja, **we do not use cookies to track your activity** or deliver personalized advertisements. The cookies we use, both first-party and
        third-party, are limited to essential functionalities, such as enabling logins and processing payments.
      </p>

      <p>
        Our use of cookies is solely to facilitate your ability to log in to our website using your Discord account via OAuth and to handle payments securely.
        These cookies are necessary to provide you with a seamless, secure login, and payment experience.
      </p>

      <h2>How Can You Control Cookies?</h2>

      <p>
        You have the right to decide whether to accept or reject cookies. You can exercise your cookie rights by setting your preferences in the Cookie Consent
        Manager. The Cookie Consent Manager allows you to select which categories of cookies you accept or reject. Essential cookies cannot be rejected as they
        are strictly necessary to provide you with services.
      </p>

      <p>
        Apart from the Cookie Consent Manager, most web browsers allow some control of most cookies through the browser settings. To find out more about
        cookies, including how to see what cookies have been set, visit <a href="https://www.aboutcookies.org">www.aboutcookies.org</a> or{' '}
        <a href="https://www.allaboutcookies.org">www.allaboutcookies.org</a>.
      </p>

      <h2>More Information</h2>

      <p>
        If you have any questions about our use of cookies or other technologies, please <SupportContactLink />.
      </p>

      <p className="text-end">Last updated: May 9th, 2024</p>
    </Layout>
  )
}
