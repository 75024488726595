import type { ReactNode } from 'react'
import { BrowserRouter, Route, Routes as RoutesContainer } from 'react-router-dom'

import { ScrollToTopView as ScrollToTop } from '../components/ScrollToTop'

import { AcceptableUsePolicyView as AcceptableUsePolicy } from './AcceptableUsePolicy'
import { AddPollView as AddPoll } from './Dashboard/GuildDetail/Polls/Add'
import { AddReactionRoleView as AddReactionRole } from './Dashboard/GuildDetail/ReactionRoles/Add'
import { CheckoutView as Checkout } from './Checkout'
import { CookiePolicyView as CookiePolicy } from './CookiePolicy'
import { DashboardView as Dashboard } from './Dashboard'
import { GuildDetailView as GuildDetail } from './Dashboard/GuildDetail'
import { HomeView as Home } from './Home'
import { LeavingMessageView as LeavingMessage } from './Dashboard/GuildDetail/LeavingMessage'
import { NotificationsView as Notifications } from './Dashboard/GuildDetail/Notifications'
import { OauthView as Oauth } from './Oauth'
import { PermissionsView as Permissions } from './Dashboard/GuildDetail/Permissions'
import { PersonalizedBotView as PersonalizedBot } from './Dashboard/GuildDetail/PersonalizedBot'
import { PollDetailView as PollDetail } from './Dashboard/GuildDetail/Polls/Detail'
import { PollsView as Polls } from './Dashboard/GuildDetail/Polls'
import { PrivacyPolicyView as PrivacyPolicy } from './PrivacyPolicy'
import { QuoteOfTheDayView as QuoteOfTheDay } from './Dashboard/GuildDetail/QuoteOfTheDay'
import { ReactionRoleDetailView as ReactionRoleDetail } from './Dashboard/GuildDetail/ReactionRoles/Detail'
import { ReactionRolesView as ReactionRoles } from './Dashboard/GuildDetail/ReactionRoles'
import { RefundPolicyView as RefundPolicy } from './RefundPolicy'
import { SecurityPolicyView as SecurityPolicy } from './SecurityPolicy'
import { SettingsView as Settings } from './Settings'
import { SubscriptionView as Subscription } from './Subscription'
import { SubscriptionsView as Subscriptions } from './Subscriptions'
import { TermsAndConditionsView as TermsAndConditions } from './TermsAndConditions'
import { TiktokIntegrationView as TiktokIntegration } from './Dashboard/GuildDetail/TiktokIntegration'
import { TwitchIntegrationView as TwitchIntegration } from './Dashboard/GuildDetail/TwitchIntegration'
import { WelcomeMessageView as WelcomeMessage } from './Dashboard/GuildDetail/WelcomeMessage'
import { YoutubeIntegrationView as YoutubeIntegration } from './Dashboard/GuildDetail/YoutubeIntegration'

import type { Page } from './pages'
import pages from './pages'

const pageElements: Record<string, () => JSX.Element> = {
  AcceptableUsePolicy,
  AddPoll,
  AddReactionRole,
  Checkout,
  CookiePolicy,
  Dashboard,
  GuildDetail,
  Home,
  LeavingMessage,
  Notifications,
  Oauth,
  Permissions,
  PersonalizedBot,
  PollDetail,
  Polls,
  PrivacyPolicy,
  QuoteOfTheDay,
  ReactionRoleDetail,
  ReactionRoles,
  RefundPolicy,
  SecurityPolicy,
  Settings,
  Subscription,
  Subscriptions,
  TermsAndConditions,
  TiktokIntegration,
  TwitchIntegration,
  WelcomeMessage,
  YoutubeIntegration
}

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <RoutesContainer>
        {pages.map(({ path, elementName }: Page): ReactNode => {
          const Element = pageElements[elementName]
          return <Route key={path} path={path} element={<Element />} />
        })}
      </RoutesContainer>
    </BrowserRouter>
  )
}

export default Routes
