import { useEffect, useState } from 'react'
import { useForm, useFieldArray } from 'react-hook-form'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { hasDuplicates } from '../../../../../../../lib/util'
import { getOptionalFields, resolver } from '../../../../../../../lib/validation'

import type { Guild } from '../../../../../../../models/Guild'
import type { ReactionRoleData } from '../../../../../../../models/ReactionRole'
import type { ReactionRoleOptionData } from '../../../../../../../models/ReactionRoleOption'

const emojiData = data as any // eslint-disable-line
const emojisByKey: Record<number | string, string> = {
  1: emojiData.emojis.one.skins[0].native,
  2: emojiData.emojis.two.skins[0].native,
  3: emojiData.emojis.three.skins[0].native,
  4: emojiData.emojis.four.skins[0].native,
  5: emojiData.emojis.five.skins[0].native,
  6: emojiData.emojis.six.skins[0].native,
  7: emojiData.emojis.seven.skins[0].native,
  8: emojiData.emojis.eight.skins[0].native,
  9: emojiData.emojis.nine.skins[0].native,
  tu: emojiData.emojis['+1'].skins[0].native,
  td: emojiData.emojis['-1'].skins[0].native
}

interface Props {
  channels: Guild['channels']
  currentThemeType: string
  guildId: string
  roles: Guild['roles']

  onSubmit: (data: ReactionRoleData) => void
  onCancel?: () => void
}

const formatReactionRoleOptionErrorMessage = (message?: string): string => {
  if (!message) {
    return ''
  }

  return message.replace(/^ReactionRoleOptions\/\d+\/text/, 'Button text').replace(/^ReactionRoleOptions\/\d+\/roleId/, 'Role')
}

let localErrors: Record<string, { message: string; type?: string }> = {}

// eslint-disable-next-line
const preprocessData = (data: Record<string, any>) => {
  const optionalFields = getOptionalFields('ReactionRoleData')
  optionalFields.forEach((optionalField: string) => {
    if (data[optionalField] === '') {
      delete data[optionalField]
    }
  })
  return data
}

export const ReactionRolesFormView = ({ channels, currentThemeType, guildId, roles, onSubmit, onCancel }: Props) => {
  const [reactionRoleType, setReactionRoleType] = useState('emojis')
  const [showEmojiPicker, setShowEmojiPicker] = useState<Record<string, boolean>>({})

  const {
    register,
    handleSubmit,
    clearErrors,
    setError,
    setFocus,
    setValue,
    control,
    formState: { errors }
  } = useForm<ReactionRoleData>({
    resolver: async (data) => {
      const result = await resolver('create', 'reaction-roles')(preprocessData(data))

      localErrors = result.errors

      setError('reactionRoleOptions.0.text', { type: 'custom', message: 'custom message' })

      const allEmojisSet = data.reactionRoleOptions.every((reactionRoleOption: ReactionRoleOptionData) => reactionRoleOption.emoji)
      const duplicateEmojisUsed = hasDuplicates(data.reactionRoleOptions, 'emoji')

      if (allEmojisSet && duplicateEmojisUsed) {
        const duplicateEmojiError = { type: 'custom', message: 'Cannot use the same emoji for more than one option' }
        setError('reactionRoleOptions', duplicateEmojiError)
        if (typeof result.errors === 'object' && result.errors !== null) {
          ;(result.errors as Record<string, any>).reactionRoleOptions = duplicateEmojiError // eslint-disable-line
        }
      }

      const allRoleIdsSet = data.reactionRoleOptions.every((reactionRoleOption: ReactionRoleOptionData) => reactionRoleOption.roleId)
      const duplicateRoleIdUsed = hasDuplicates(data.reactionRoleOptions, 'roleId')
      if (allRoleIdsSet && duplicateRoleIdUsed) {
        const duplicateRoleIdError = { type: 'custom', message: 'Cannot use the same role for more than one option' }
        setError('reactionRoleOptions', duplicateRoleIdError)
        if (typeof result.errors === 'object' && result.errors !== null) {
          ;(result.errors as Record<string, any>).reactionRoleOptions = duplicateRoleIdError // eslint-disable-line
        }
      }

      return result
    },
    defaultValues: {
      name: '',
      description: '',
      channelId: '',
      guildId,
      reactionRoleOptions: [
        {
          emoji: emojisByKey[1],
          guildId
        },
        {
          emoji: emojisByKey[2],
          guildId
        }
      ]
    }
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'reactionRoleOptions'
  })

  useEffect(() => {
    setTimeout(() => {
      setFocus('name')
    }, 0)
  }, [setFocus])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="rtn-form-reaction-role-name" className="fw-bold">
            Name:
          </Form.Label>
          <Col sm="10">
            <Form.Control id="rtn-form-reaction-role-name" isInvalid={!!errors.name?.message} {...register('name')} />
            <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className="mb-4">
          <Form.Label column sm="2" htmlFor="rtn-form-reaction-role-description" className="fw-bold">
            Description (Optional):
          </Form.Label>
          <Col sm="10">
            <Form.Control
              id="rtn-form-reaction-role-description"
              as="textarea"
              rows={4}
              isInvalid={!!errors.description?.message}
              {...register('description')}
            />
            <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Row className="mb-4">
          <Col md={2} className="fw-bold">
            Format:
          </Col>
          <Col md={2}>
            <Button variant={reactionRoleType === 'emojis' ? 'success' : 'danger'} onClick={() => setReactionRoleType('emojis')}>
              Emojis
            </Button>
          </Col>
          <Col md={2}>
            <Button variant={reactionRoleType === 'buttons' ? 'success' : 'danger'} onClick={() => setReactionRoleType('buttons')}>
              Buttons
            </Button>
          </Col>
          <Col md={2} className="fw-bold d-flex justify-content-end">
            Channel:
          </Col>
          <Col md={4}>
            <select
              className={`form-select form-select-md ${!!localErrors.channelId?.message ? 'is-invalid' : ''}`}
              style={{ width: 'auto', display: 'inline' }}
              aria-label="channel selection dropdown"
              {...register('channelId')}
            >
              {[
                <option key="select channel" value="">
                  Select a channel
                </option>
              ].concat(
                (channels || []).map((channel) => {
                  return (
                    <option key={channel.id} value={channel.id}>
                      {channel.name}
                    </option>
                  )
                })
              )}
            </select>
            <Form.Control.Feedback type="invalid">{errors.channelId?.message?.replace(/ChannelId/, 'Channel')}</Form.Control.Feedback>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col className="d-flex justify-content-center">
            <h4>Reaction Role Options</h4>
          </Col>
        </Row>
        {fields.map((field, index) => (
          <Row key={field.id} className="mb-2">
            {reactionRoleType === 'emojis' ? (
              <Col md={{ offset: 2, span: 2 }}>
                <span className="fw-bold">Emoji:</span>
                {showEmojiPicker[field.id] ? (
                  <div style={{ position: 'absolute', zIndex: '100', bottom: '0' }}>
                    <Picker
                      data={data}
                      theme={currentThemeType}
                      onClickOutside={() => setShowEmojiPicker({ ...showEmojiPicker, [field.id]: false })}
                      onEmojiSelect={(selectedEmoji: { native: string }) => {
                        setValue(`reactionRoleOptions.${index}.emoji`, selectedEmoji.native)
                        setShowEmojiPicker({ ...showEmojiPicker, [field.id]: false })
                      }}
                    />
                  </div>
                ) : null}
                {!showEmojiPicker[field.id] ? (
                  <Form.Control
                    style={{ textAlign: 'center' }}
                    isInvalid={!!errors.reactionRoleOptions?.[index]?.emoji}
                    {...register(`reactionRoleOptions.${index}.emoji`)}
                    onFocus={() => setShowEmojiPicker({ ...showEmojiPicker, [field.id]: true })}
                  />
                ) : null}
              </Col>
            ) : (
              <Col>
                <span className="fw-bold">Button Text:</span>
                <Form.Control
                  id={`rtn-form-poll-option-${field.id}`}
                  isInvalid={!!localErrors[`reactionRoleOptions/${index}/text`]?.message}
                  placeholder={`Button text ${index + 1}`}
                  {...register(`reactionRoleOptions.${index}.text`)}
                />
                <Form.Control.Feedback type="invalid">
                  {formatReactionRoleOptionErrorMessage(localErrors[`reactionRoleOptions/${index}/text`]?.message)}
                </Form.Control.Feedback>
              </Col>
            )}
            <Col sm={6} className="d-flex flex-column">
              <span className="fw-bold">Role:</span>
              <select
                className={`form-select form-select-md ${!!localErrors[`reactionRoleOptions/${index}/roleId`]?.message ? 'is-invalid' : ''}`}
                style={{ width: 'auto', display: 'inline' }}
                aria-label="role selection dropdown"
                {...register(`reactionRoleOptions.${index}.roleId`)}
              >
                {[
                  <option key="select role option" value="">
                    Select a role
                  </option>
                ].concat(
                  (roles || []).map((role) => {
                    return (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    )
                  })
                )}
              </select>
              <Form.Control.Feedback type="invalid">
                {formatReactionRoleOptionErrorMessage(localErrors[`reactionRoleOptions/${index}/roleId`]?.message)}
              </Form.Control.Feedback>
            </Col>
            <Col
              md={2}
              className={`d-flex align-items-${
                localErrors[`reactionRoleOptions/${index}/text`]?.message || localErrors[`reactionRoleOptions/${index}/roleId`]?.message ? 'center' : 'end'
              }`}
            >
              <Button onClick={() => remove(index)}>Remove</Button>
            </Col>
          </Row>
        ))}
        {errors.reactionRoleOptions?.message ? (
          <Row className="mb-3">
            <Col className="text-center text-danger">
              {errors.reactionRoleOptions?.message.replace(/^ReactionRoleOptions/, 'Reaction Role Options').replace(/items$/, 'options')}
            </Col>
          </Row>
        ) : null}
        <Row className="mt-5">
          <Col md={{ offset: 3, span: 6 }} className="d-flex justify-content-center">
            <Button
              onClick={() => {
                if (fields.length >= 10) {
                  setError('reactionRoleOptions', { type: 'maxLength', message: 'You may only specify a maximum of 10 reaction role options' })
                } else {
                  append({ emoji: '', guildId, roleId: '' })
                  const newIndex = fields.length
                  setValue(`reactionRoleOptions.${newIndex}.emoji`, emojisByKey[newIndex + 1])
                  clearErrors('reactionRoleOptions')
                }
              }}
            >
              Add Reaction Role Option
            </Button>
          </Col>
        </Row>
        <Row>
          <Col className="d-flex justify-content-center align-items-center mt-3">
            <Button type="submit">Submit</Button>
            {onCancel ? (
              <Button onClick={onCancel} className="ms-3">
                Cancel
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </form>
  )
}
