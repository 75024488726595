import { connect } from 'react-redux'

import type { RootState } from '../../redux/store'
import { selectGuildsData, selectGuildsLoading } from '../../redux/selectors'

import { GuildsView } from './GuildsView'

const mapStateToProps = (state: RootState) => ({
  guilds: selectGuildsData(state),
  loading: selectGuildsLoading(state)
})

export const Guilds = connect(mapStateToProps)(GuildsView)
