import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout'
import { Permissions } from '../../../../components/Guilds/GuildDetail/Permissions'

export const PermissionsView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <Permissions guildId={guildId!} />
    </Layout>
  )
}
