import { type NavigateFunction, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'

import serverIcon from '../../../assets/discord-server-logo.webp'

import type { Guild } from '../../../models/Guild'

import { openDiscordBotOauthUrl } from '../../../lib/util'
import { SyncManager } from '../../../lib/feathers/lib/syncManager'

import './styles.css'

const addBot = (guildId: string, navigate: NavigateFunction): void => {
  SyncManager.setDiscordMainBotOauthWindowByGuildId(guildId, openDiscordBotOauthUrl(guildId), () => navigate(`/dashboard/${guildId}`))
}

interface Props {
  guild: Guild
}

export const GuildCardView = ({ guild }: Props) => {
  const navigate = useNavigate()

  return (
    <Card className="guild-card-container">
      <Card.Img
        variant="top"
        src={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png` : serverIcon}
        style={{ borderRadius: '10px' }}
      />
      <Card.Body className="guild-card-body">
        <Card.Title>{guild.name}</Card.Title>
        <br />
        {guild.hasBot ? (
          <Button className="guild-card-button" onClick={() => navigate(`/dashboard/${String(guild.id)}`)}>
            View Server
          </Button>
        ) : (
          <Button className="guild-card-button" onClick={() => addBot(String(guild.id), navigate)}>
            Add Bot
          </Button>
        )}
      </Card.Body>
    </Card>
  )
}
