import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

export const NoIntegrationYetCard = ({ startOauthFlow }: { startOauthFlow: () => void }) => {
  return (
    <Row className="justify-content-center mt-3 text-center">
      <Col md={6} lg={4}>
        <Card>
          <Card.Header>You do not have an integration yet.</Card.Header>
          <Card.Body>
            <Card.Title className="mt-2 mb-4">Click on the button below to get started by authorizing RT Ninja with Twitch.</Card.Title>
            <Button onClick={startOauthFlow}>Authorize Twitch</Button>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
