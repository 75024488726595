import { useLocation, useParams } from 'react-router-dom'

import { Layout } from '../../components/Layout'
import { Checkout } from '../../components/Checkout'

export const CheckoutView = () => {
  const { guildId, plan, planDuration, provider } = useParams()
  const location = useLocation()

  const complete = location.pathname.indexOf('/complete') !== -1

  return (
    <Layout>
      <Checkout complete={complete} guildId={guildId!} plan={plan!} planDuration={planDuration!} provider={provider} />
    </Layout>
  )
}
