import bots from './features/bots'
import connection from './features/connection'
import dailyQuotes from './features/dailyQuotes'
import guilds from './features/guilds'
import leavingMessages from './features/leavingMessages'
import modal from './features/modal'
import notifications from './features/notifications'
import permissions from './features/permissions'
import polls from './features/polls'
import pollOptions from './features/pollOptions'
import pollVotes from './features/pollVotes'
import reactionRoles from './features/reactionRoles'
import reactionRoleOptions from './features/reactionRoleOptions'
import subscriptions from './features/subscriptions'
import theme from './features/theme'
import tiktokIntegrations from './features/tiktokIntegrations'
import toasts from './features/toasts/toastsSlice'
import twitchIntegrations from './features/twitchIntegrations'
import users from './features/users/usersSlice'
import welcomeMessages from './features/welcomeMessages'
import youtubeIntegrations from './features/youtubeIntegrations'

const reducers = {
  bots,
  connection,
  'daily-quotes': dailyQuotes,
  guilds,
  'leaving-messages': leavingMessages,
  notifications,
  modal,
  permissions,
  polls,
  'poll-options': pollOptions,
  'poll-votes': pollVotes,
  'reaction-roles': reactionRoles,
  'reaction-role-options': reactionRoleOptions,
  subscriptions,
  theme,
  'tiktok-integrations': tiktokIntegrations,
  toasts,
  'twitch-integrations': twitchIntegrations,
  users,
  'welcome-messages': welcomeMessages,
  'youtube-integrations': youtubeIntegrations
}

export default reducers
