import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../redux/store'
import {
  selectBotsLoading,
  selectGuildsData,
  selectGuildsLoading,
  selectSubscriptionsDataForCurrentUser,
  selectSubscriptionsLoading
} from '../../redux/selectors'
import { closeAll, open } from '../../redux/features/modal/modalSlice'
import { patchAsync as patchSubscriptionAsync } from '../../redux/features/subscriptions/subscriptionsSlice'

import { SubscriptionsView } from './SubscriptionsView'

const mapStateToProps = (state: RootState) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectSubscriptionsLoading(state)

  return {
    guilds: selectGuildsData(state),
    loading,
    subscriptions: selectSubscriptionsDataForCurrentUser(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  cancelSubscription: (id: string, cancelReason?: string) => {
    dispatch(closeAll())
    dispatch(patchSubscriptionAsync({ id, data: { canceledAt: new Date().toISOString(), cancelReason } }))
  },
  openModal: (modalName: string): void => {
    dispatch(open(modalName))
  },
  resumeSubscription: (id: string) => {
    dispatch(closeAll())
    dispatch(patchSubscriptionAsync({ id, data: { canceledAt: new Date().toISOString(), cancelReason: 'resume' } }))
  }
})

export const Subscriptions = connect(mapStateToProps, mapDispatchToProps)(SubscriptionsView)
