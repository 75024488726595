import { Layout } from '../../components/Layout'
import { Subscriptions } from '../../components/Subscriptions'

export const SubscriptionsView = () => {
  return (
    <Layout showFooter>
      <Subscriptions />
    </Layout>
  )
}
