import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import { formatDateShort } from '../../../../../lib/util'

import { Loading } from '../../../../Loading/Loading'
import { Modal } from '../../../../Modal/Modal'
import { NoBotInGuildView } from '../../../../Bots/NoBotInGuild/NoBotInGuildView'

import type { Guild } from '../../../../../models/Guild'
import type { Poll, PollPatch } from '../../../../../models/Poll'
import type { PollOption } from '../../../../../models/PollOption'
import type { PollVote } from '../../../../../models/PollVote'

import { BarGraphView as BarGraph } from './components/BarGraph'
import { PieGraphView as PieGraph } from './components/PieGraph'

interface Props {
  canClosePoll: boolean
  canRemovePoll: boolean
  loading: boolean

  guild?: Guild
  poll?: Poll
  pollOptions?: PollOption[]
  pollVotes?: PollVote[]

  load: () => void
  openModal: (modalName: string) => void
  patchPoll: (data: PollPatch) => void
  removePoll: () => Promise<void>
}

export const PollDetailView = ({
  canClosePoll,
  canRemovePoll,
  guild,
  loading,
  poll,
  pollOptions = [],
  pollVotes = [],
  load,
  openModal,
  patchPoll,
  removePoll
}: Props) => {
  const { viewMode } = useParams()
  const navigate = useNavigate()

  useEffect(load, [load])

  const handleConfirmRemovePoll = async (): Promise<void> => {
    await removePoll()
    navigate(`/dashboard/${poll?.guildId}/polls`)
  }

  const handleConfirmClosePoll = (): void => {
    patchPoll({ closedAt: new Date().toISOString() })
  }

  const handleChangeViewMode = (mode: string) => {
    if (mode === 'list') {
      navigate(`/dashboard/${poll?.guildId}/polls/${poll?.id}`)
    } else {
      navigate(`/dashboard/${poll?.guildId}/polls/${poll?.id}/${mode}`)
    }
  }

  const backButton = document.getElementById('poll-detail-view-back-button')
  const barColor = backButton ? window.getComputedStyle(backButton).backgroundColor : '#8884d8'

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuildView guild={guild} />
  ) : (
    <Container>
      <Row className="mb-3">
        <Col md={2}>
          <Button id="poll-detail-view-back-button" onClick={() => navigate(`/dashboard/${poll?.guildId}/polls`)}>
            Back
          </Button>
        </Col>
        <Col md={{ offset: 1, span: 6 }}>
          <Card>
            <Card.Header>
              <h2 className="text-center">Poll Details {poll?.closedAt ? '(Closed)' : ''}</h2>
              {poll?.closesAt ? <h5 className="text-center">Closes at {formatDateShort(poll.closesAt)}</h5> : null}
            </Card.Header>
            <Card.Body>
              <Card.Title>{poll?.title}</Card.Title>
              {poll?.description ? <Card.Text>{poll.description}</Card.Text> : null}
            </Card.Body>
          </Card>
        </Col>
        <Col className="text-end" md={{ offset: 1, span: 2 }}>
          {canRemovePoll ? (
            <Button variant="danger" className="mb-3" onClick={() => openModal('confirmRemovePoll')}>
              Remove Poll
            </Button>
          ) : null}
          {!poll?.closedAt && canClosePoll ? (
            <Button variant="danger" onClick={() => openModal('confirmClosePoll')}>
              Close Poll
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row className="my-4 text-center">
        <Col md={{ offset: 3, span: 2 }}>
          <Button variant={!viewMode ? 'primary' : 'secondary'} onClick={() => handleChangeViewMode('list')}>
            List
          </Button>
        </Col>
        <Col md={2}>
          <Button variant={viewMode === 'pie' ? 'primary' : 'secondary'} onClick={() => handleChangeViewMode('pie')}>
            Pie Graph
          </Button>
        </Col>
        <Col md={2}>
          <Button variant={viewMode === 'bar' ? 'primary' : 'secondary'} onClick={() => handleChangeViewMode('bar')}>
            Bar Graph
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <h4 className="text-center">Results</h4>
        </Col>
      </Row>
      {!viewMode ? (
        <>
          <Row className="mb-3">
            <Col md={{ offset: 3, span: 4 }}>
              <h5>Option</h5>
            </Col>
            <Col md={2} className="text-center">
              <h5>Votes</h5>
            </Col>
          </Row>
          {[...(pollOptions || [])]
            .sort((a, b) => {
              const aVotes = pollVotes?.filter((pollVote: PollVote) => pollVote.optionId === a.id).length || 0
              const bVotes = pollVotes?.filter((pollVote: PollVote) => pollVote.optionId === b.id).length || 0
              return bVotes - aVotes
            })
            .map((pollOption) => (
              <Row key={pollOption.id}>
                <Col md={{ offset: 3, span: 4 }}>{pollOption.text}</Col>
                <Col md={2} className="text-center">
                  {pollVotes?.filter((pollVote: PollVote) => pollVote.optionId === pollOption.id).length}
                </Col>
              </Row>
            ))}
        </>
      ) : viewMode === 'pie' ? (
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <PieGraph pollOptions={pollOptions} pollVotes={pollVotes} />
          </Col>
        </Row>
      ) : viewMode === 'bar' ? (
        <Row>
          <Col md={{ offset: 2, span: 8 }}>
            <BarGraph barColor={barColor} pollOptions={pollOptions} pollVotes={pollVotes} />
          </Col>
        </Row>
      ) : null}
      <Modal modalName="confirmRemovePoll" title="Confirm Remove Poll" accept={handleConfirmRemovePoll} saveButtonText="Remove">
        <h1 className="text-danger text-center">Warning</h1>
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-x-circle text-danger me-3 fs-1" />
          Are you sure you wish to remove this poll? This action cannot be undone.
        </div>
      </Modal>
      <Modal modalName="confirmClosePoll" title="Confirm Close Poll" accept={handleConfirmClosePoll} saveButtonText="Close">
        <h1 className="text-danger text-center">Warning</h1>
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-x-circle text-danger me-3 fs-1" />
          Are you sure you wish to close this poll? Votes will no longer be counted.
        </div>
      </Modal>
    </Container>
  )
}
