import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../../redux/store'
import {
  selectBotDataByGuildId,
  selectBotsLoading,
  selectCurrentUser,
  selectCurrentThemeType,
  selectGuildData,
  selectGuildsLoading,
  selectReactionRolesLoading
} from '../../../../../redux/selectors'
import type { ReactionRoleData } from '../../../../../models/ReactionRole'
import { createAsync } from '../../../../../redux/features/reactionRoles/reactionRolesSlice'

import { AddReactionRoleView } from './AddReactionRoleView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  return {
    bot: selectBotDataByGuildId(ownProps.guildId, state),
    currentUser: selectCurrentUser(state),
    currentThemeType: selectCurrentThemeType(state),
    guild: selectGuildData(ownProps.guildId, state),
    loading: selectBotsLoading(state) || selectGuildsLoading(state) || selectReactionRolesLoading(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addReactionRole: async (data: ReactionRoleData): Promise<boolean> => {
    try {
      const res = await dispatch(createAsync({ data }))
      return !createAsync.rejected.match(res)
    } catch (e: unknown) {
      return false
    }
  }
})

export const AddReactionRole = connect(mapStateToProps, mapDispatchToProps)(AddReactionRoleView)
