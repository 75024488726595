import { dataValidator } from '@rtninja/rtninja-backend-client'
import type { DomainModelData, DomainModelPatch } from './api'
import { capitalizeFirstLetter, convertServiceToModelName } from './util'

interface AjvError {
  instancePath: string
  message: string
  keyword: string
  params: Record<string, string | number>
  schemaPath: string
}

interface ParsedError {
  type: string
  message: string
}

const getSchemaName = (method: string, service: string) => {
  return `${convertServiceToModelName(service)}${method === 'create' ? 'Data' : 'Patch'}`
}

const getFieldName = (error: AjvError): string => error.instancePath.replace(/\//, '')

const getMessageForError = (error: AjvError, service: string): string => {
  const fieldName = capitalizeFirstLetter(getFieldName(error))
  const limit = error.params.limit

  if (error.keyword === 'required' || (error.keyword === 'minLength' && limit === 1)) {
    return `${fieldName} is required`
  }

  if (error.keyword === 'minLength') {
    return `${fieldName} must not be less than ${limit} characters`
  }

  if (error.keyword === 'maxLength') {
    return `${fieldName} must not be more than ${limit} characters`
  }

  if (error.keyword === 'minItems') {
    return `${fieldName} must have at least ${limit} items`
  }

  if (error.keyword === 'pattern') {
    if (service === 'roles') {
      return `${fieldName} may only contain letters`
    }
  }

  return error.message
}

const parseErrors = (errors: AjvError[], service: string) => {
  return errors.reduce((errorsResult: Record<string, ParsedError>, error: AjvError) => {
    errorsResult[getFieldName(error)] = {
      type: error.keyword,
      message: getMessageForError(error, service)
    }
    return errorsResult
  }, {})
}

export const resolver = (method: string, service: string) => async (values: DomainModelData | DomainModelPatch) => {
  try {
    await dataValidator.validate(getSchemaName(method, service), values)
    return {
      values,
      errors: {}
    }
  } catch (e: unknown) {
    return {
      values: {},
      errors: parseErrors((e as { errors: AjvError[] }).errors, service)
    }
  }
}

export const getValueForRuleOfField = (schemaName: string, field: string, rule: string) => {
  return (dataValidator.refs[schemaName] as any).schema.properties[field][rule] // eslint-disable-line
}

export const getOptionalFields = (schemaName: string) => {
  const properties = (dataValidator.refs[schemaName] as any).schema.properties // eslint-disable-line
  return Object.keys(properties).filter((fieldName: string) => {
    const keys = Object.getOwnPropertySymbols(properties[fieldName])
    return keys.some((key: symbol) => {
      return fieldName !== 'id' && properties[fieldName][key] === 'Optional'
    })
  })
}
