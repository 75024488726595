import { useParams } from 'react-router-dom'

import { Layout } from '../../components/Layout'
import { Subscription } from '../../components/Subscription'

export const SubscriptionView = () => {
  const { guildId } = useParams()

  return (
    <Layout showFooter>
      <Subscription guildId={guildId} />
    </Layout>
  )
}
