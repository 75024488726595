import { useForm } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

interface Props {
  existingEmail?: string
  onSubmit: (data: { email: string }) => void
}

export const EmailFormView = ({ existingEmail = '', onSubmit }: Props) => {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<{ email: string }>({
    defaultValues: {
      email: existingEmail
    }
  })

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Form.Group as={Row} className="mb-3">
          <Form.Label column sm="2" htmlFor="email" className="fw-bold">
            E-mail:
          </Form.Label>
          <Col sm="10">
            <Form.Control
              id="email"
              type="email"
              isInvalid={!!errors.email}
              {...register('email', { required: 'E-mail is required', pattern: { value: /^\S+@\S+\.\S+$/, message: 'Invalid e-mail address' } })}
            />
            <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Row className="justify-content-center">
          <Col sm="2" className="text-center">
            <Button type="submit">{existingEmail ? 'Update' : 'Register'}</Button>
          </Col>
        </Row>
      </Form>
    </Container>
  )
}
