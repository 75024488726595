import { useParams } from 'react-router-dom'

import { Layout } from '../../../../../components/Layout'
import { PollDetail } from '../../../../../components/Guilds/GuildDetail/Polls/Detail'

export const PollDetailView = () => {
  const { guildId, pollId } = useParams()

  return (
    <Layout>
      <PollDetail guildId={guildId!} pollId={pollId!} />
    </Layout>
  )
}
