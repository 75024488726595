import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout'
import { TiktokIntegration } from '../../../../components/Guilds/GuildDetail/TiktokIntegration'

export const TiktokIntegrationView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <TiktokIntegration guildId={guildId!} />
    </Layout>
  )
}
