import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'

import { Modal } from '../Modal'
import { Loading } from '../Loading'
import { PageHeaderView as PageHeader } from '../PageHeader'

import serverIcon from '../../assets/discord-server-logo.webp'

import type { Guild } from '../../models/Guild'
import type { Subscription } from '../../models/Subscription'

import { capitalizeFirstLetter, formatDate } from '../../lib/util'
import { isSubscriptionActive, isSubscriptionNew } from '../../lib/subscription'

import './styles.css'

interface Props {
  cancelSubscription: (id: string, cancelReason?: string) => void
  guilds: Guild[]
  loading: boolean
  openModal: (modalName: string) => void
  subscriptions: Subscription[]
  resumeSubscription: (id: string) => void
}

const cancelReasons = ['Too expensive', 'Found a better alternative', "Doesn't have the features I want", 'No longer needed', 'Poor customer service', 'Other']

export const SubscriptionsView = ({ cancelSubscription, guilds, loading, openModal, resumeSubscription, subscriptions }: Props) => {
  const navigate = useNavigate()
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription | null>(null)
  const [selectedReasons, setSelectedReasons] = useState<Array<string>>([])
  const [otherCancelReason, setOtherCancelReason] = useState<string>('')

  const handleConfirmCancelSubscription = async () => {
    if (selectedReasons.length) {
      const cancelReason = selectedReasons.join(', ')
      if (selectedReasons.includes('Other')) {
        cancelReason.concat(`: ${otherCancelReason}`)
      }

      cancelSubscription(selectedSubscription!.id, cancelReason)
      setSelectedReasons([])
    }
  }

  const handleConfirmResumeSubscription = async () => {
    resumeSubscription(selectedSubscription!.id)
  }

  const handleCancelClick = (subscription: Subscription): void => {
    setSelectedSubscription(subscription)
    openModal('confirmCancelSubscription')
  }

  const handleReasonClick = (reason: string): void => {
    setSelectedReasons((previousReasons) => (previousReasons.includes(reason) ? previousReasons.filter((r) => r !== reason) : [...previousReasons, reason]))
  }

  const handleResumeClick = (subscription: Subscription): void => {
    setSelectedSubscription(subscription)
    openModal('confirmResumeSubscription')
  }

  const handleReactivateClick = (subscription: Subscription): void => {
    navigate(`/checkout/${subscription.guildId}/${subscription.plan}/${subscription.planDuration}`)
  }

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <PageHeader>Subscriptions</PageHeader>
      {!subscriptions.length ? <h3 className="text-center mt-5">You do not have any active subscriptions.</h3> : null}
      {subscriptions.map((subscription) => {
        const subscriptionIsActive = isSubscriptionActive(subscription)
        const subscriptionIsNew = isSubscriptionNew(subscription)
        const guild = guilds.find((guild: { id: string }) => guild.id === subscription.guildId)!

        return (
          <Row key={subscription.id} className="mb-3">
            <Col>
              <Card>
                <Container>
                  <Row>
                    <Col md={1} lg={1} className="d-flex align-items-center justify-content-center">
                      <Image
                        className="cursor-pointer"
                        onClick={() => navigate(`/dashboard/${guild.id}`)}
                        style={{ borderRadius: '10px', height: '60px', width: '60px' }}
                        src={guild.icon ? `https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png` : serverIcon}
                      />
                    </Col>
                    <Col md={3} lg={3} className="d-flex align-items-center justify-content-center">
                      <h4 className="cursor-pointer" onClick={() => navigate(`/dashboard/${guild.id}`)}>
                        {guild.name} {subscription.canceledAt ? '(Canceled)' : !subscriptionIsActive ? '(Inactive)' : ''}
                      </h4>
                    </Col>
                    <Col md={6} lg={6} className="d-flex flex-column align-items-start justify-content-center">
                      <h5 className="mt-2 ms-5">Plan: {capitalizeFirstLetter(subscription.plan)}</h5>
                      <h5 className="ms-5">
                        {`Effective Date: ${subscriptionIsActive && subscription.effectiveDate ? formatDate(subscription.effectiveDate, 'MMMM d, yyyy') : 'Payment Pending'}`}
                      </h5>
                      <h5 className="ms-5">{`Expires At: ${subscriptionIsActive ? formatDate(subscription.expiresAt, 'MMMM d, yyyy') : 'Payment Pending'}`}</h5>
                      {subscription.providerErrorMessage ? <h5 className="ms-5 text-danger">Payment Error: {subscription.providerErrorMessage}</h5> : null}
                    </Col>
                    <Col md={2} lg={2} className="d-flex align-items-center justify-content-center">
                      {subscriptionIsActive && !subscription.canceledAt ? (
                        <Button variant="danger" onClick={() => handleCancelClick(subscription)}>
                          Cancel Subscription
                        </Button>
                      ) : subscriptionIsActive && subscription.canceledAt ? (
                        <Button variant="success" onClick={() => handleResumeClick(subscription)}>
                          Resume Subscription
                        </Button>
                      ) : !subscriptionIsNew ? (
                        <Button variant="info" onClick={() => handleReactivateClick(subscription)}>
                          Reactivate Subscription
                        </Button>
                      ) : null}
                    </Col>
                  </Row>
                </Container>
                <Card.Title></Card.Title>
              </Card>
            </Col>
          </Row>
        )
      })}
      <Modal
        modalName="confirmCancelSubscription"
        title="Confirm Cancel Subscription"
        accept={handleConfirmCancelSubscription}
        saveButtonText="Confirm"
        headerCentered
      >
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-x-circle text-danger me-3 fs-1" />
          Are you sure you wish to cancel your subscription? You will still be able to use all of the features of your plan until the end of the billing period.
        </div>
        <div className="d-flex flex-column align-items-center mt-2">
          <h5>Please tell us why you are cancelling:</h5>
          {cancelReasons.map((reason) => (
            <Button
              key={reason}
              variant={selectedReasons.includes(reason) ? 'success' : 'info'}
              className="w-75 mb-2"
              onClick={() => handleReasonClick(reason)}
            >
              {reason}
            </Button>
          ))}
          {selectedReasons.includes('Other') ? (
            <Form.Control
              className="w-75 mt-2"
              type="text"
              placeholder="Please specify"
              value={otherCancelReason}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => setOtherCancelReason(event.target.value)}
            />
          ) : null}
        </div>
      </Modal>
      <Modal
        modalName="confirmResumeSubscription"
        title="Confirm Resume Subscription"
        accept={handleConfirmResumeSubscription}
        saveButtonText="Confirm"
        headerCentered
      >
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-x-circle text-danger me-3 fs-1" />
          Are you sure you wish to resume your subscription? You will not be billed again until the end of the billing period.
        </div>
      </Modal>
    </Container>
  )
}
