import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout'
import { WelcomeMessage } from '../../../../components/Guilds/GuildDetail/WelcomeMessage'

export const WelcomeMessageView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <WelcomeMessage guildId={guildId!} />
    </Layout>
  )
}
