import Container from 'react-bootstrap/Container'

import type { Bot, BotPatch } from '../../../../models/Bot'
import type { Guild } from '../../../../models/Guild'

import { PageHeaderView as PageHeader } from '../../../PageHeader'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

import { PersonalizedBotTokenFormView as PersonalizedBotTokenForm } from './components/PersonalizedBotTokenForm'
import { PersonalizedBotDetailsFormView as PersonalizedBotDetailsForm } from './components/PersonalizedBotDetailsForm'

interface Props {
  bot?: Bot
  guild?: Guild
  authorizing: boolean
  loading: boolean
  updateBot: (id: string) => (data: BotPatch) => void
}

export const PersonalizedBotView = ({ authorizing, loading, bot, guild, updateBot }: Props) => {
  return authorizing ? (
    <Loading text="Authorizing personalized bot..." style={{ marginTop: '32vh' }} />
  ) : loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>Personalized Bot</PageHeader>
      {bot?.personalizedBot ? (
        <PersonalizedBotDetailsForm bot={bot} onSubmit={updateBot(bot?.id!)} />
      ) : (
        <PersonalizedBotTokenForm onSubmit={updateBot(bot?.id!)} />
      )}
    </Container>
  )
}
