import { useParams } from 'react-router-dom'

import { Layout } from '../../../../../components/Layout'
import { AddReactionRole } from '../../../../../components/Guilds/GuildDetail/ReactionRoles/Add'

export const AddReactionRoleView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <AddReactionRole guildId={guildId!} />
    </Layout>
  )
}
