import { useNavigate, useParams } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { Themes } from './components/Themes'

export const SettingsView = () => {
  const { viewMode } = useParams()
  const navigate = useNavigate()

  return (
    <Container>
      <Row className="text-center mb-4">
        <Col sm={{ offset: 4, span: 4 }}>
          <Button variant={!viewMode ? 'primary' : 'secondary'} onClick={() => navigate('/settings')}>
            Themes
          </Button>
        </Col>
      </Row>
      <Row>{!viewMode ? <Themes /> : null}</Row>
    </Container>
  )
}
