import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Layout } from '../../components/Layout'
import { Commands } from './components/Commands'

export const HomeView = () => {
  const navigate = useNavigate()

  const handleFeatureClick = () => navigate('/dashboard')

  const staging = window.location.hostname === 'staging.rt.ninja'

  return (
    <Layout showFooter showWhenLoading>
      <Container>
        <Row className="mt-5">
          <Col>
            <h1 className="text-center">Welcome to the {staging ? 'Staging ' : ''}RT Ninja Discord Bot Dashboard</h1>
          </Col>
        </Row>

        <Row className="my-5">
          <Col md={{ offset: 3, span: 6 }}>
            <Card>
              <Card.Header>Get Started</Card.Header>
              <Card.Body>
                <Card.Title>Add the RT Ninja bot to your server</Card.Title>
                <Card.Text>
                  Click on the button below to view a list of Discord servers that you are an owner, admin or have manage server permissions in.
                </Card.Text>
                <div className="text-center">
                  <Button variant="primary" className="fs-2 py-2 px-5 mx-auto" onClick={handleFeatureClick}>
                    View My Servers
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <Card>
              <Card.Header>Join Our Community</Card.Header>
              <Card.Body>
                <Card.Title>Be Part of the Excitement</Card.Title>
                <Card.Text>
                  Join our Discord server to stay up-to-date with the latest features, give feedback, and be part of our growing community. Your input helps
                  shape the future of our platform!
                </Card.Text>
                <a href="https://discord.gg/YkBZnGBuyf" target="_blank" rel="noopener noreferrer" className="btn btn-primary">
                  Join Our Discord
                </a>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <Card>
              <Card.Header>Commands</Card.Header>
              <Card.Body>
                <Commands />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}
