import { useParams } from 'react-router-dom'

import { Layout } from '../../../components/Layout'
import { GuildDetail } from '../../../components/Guilds/GuildDetail/GuildDetail'

export const GuildDetailView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <GuildDetail guildId={guildId!} />
    </Layout>
  )
}
