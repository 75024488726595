import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

import type { Guild } from '../../../../models/Guild'
import type { TiktokIntegration } from '../../../../models/TiktokIntegration'

import { PageHeaderView as PageHeader } from '../../../PageHeader/PageHeaderView'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

interface Props {
  loading: boolean
  startOauthFlow: () => void

  guild?: Guild
  tiktokIntegration?: TiktokIntegration
}

export const TiktokIntegrationView = ({ loading, guild, startOauthFlow, tiktokIntegration }: Props) => {
  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>TikTok Integration</PageHeader>
      <Button onClick={startOauthFlow}>Click</Button>
    </Container>
  )
}
