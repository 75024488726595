import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { Loading } from '../../../../Loading'
import { NoBotInGuildView } from '../../../../Bots/NoBotInGuild'

import type { Bot } from '../../../../../models/Bot'
import type { Guild } from '../../../../../models/Guild'
import type { ReactionRoleData } from '../../../../../models/ReactionRole'
import type { User } from '../../../../../models/User'

import { ReactionRolesFormView as ReactionRolesForm } from './components/ReactionRoleForm'

interface Props {
  currentThemeType: string
  guildId: string
  loading: boolean

  bot?: Bot
  currentUser?: User
  guild?: Guild

  addReactionRole: (data: ReactionRoleData) => Promise<boolean>
}

export const AddReactionRoleView = ({ bot, currentUser, currentThemeType, guild, guildId, loading, addReactionRole }: Props) => {
  const navigate = useNavigate()

  const handleOnSubmit = async (data: ReactionRoleData): Promise<void> => {
    const reactionRoleCreated = await addReactionRole(data)
    if (reactionRoleCreated) {
      navigate(`/dashboard/${guildId}/reaction-roles`)
    }
  }

  const botRolePosition = guild?.roles?.find((role) => role.id === bot?.roleId)?.position || 0
  const roles = guild?.roles?.filter((role) => role.position < botRolePosition && !role.managed)

  return !currentUser || loading ? (
    <Loading text="Creating reaction role..." style={{ marginTop: '32vh' }} />
  ) : !guild || !guild?.hasBot ? (
    <NoBotInGuildView guild={guild} />
  ) : (
    <Container>
      <Row>
        <Col className="d-flex align-items-center position-relative">
          <div className="w-100 text-center mb-3">
            <h2>New Reaction Role</h2>
          </div>
        </Col>
      </Row>
      <Row style={{ width: '60%', margin: '0 auto' }}>
        <Col>
          <ReactionRolesForm onSubmit={handleOnSubmit} channels={guild.channels || []} guildId={guild.id} roles={roles} currentThemeType={currentThemeType} />
        </Col>
      </Row>
    </Container>
  )
}
