import type { ReactNode } from 'react'
import { useLocation } from 'react-router-dom'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'

import { Loading } from '../Loading'

import pages, { type Page } from '../../pages/pages'

import { Header } from '../Header'
import { Login } from '../Login'
import { Toasts } from '../Toasts'
import { ThemeSetter } from '../ThemeSetter'
import { Footer } from './components/Footer'

import './styles.css'

interface Props {
  children: ReactNode
  loggedIn: boolean
  loading: boolean

  showFooter?: boolean
  showWhenLoading?: boolean
}

export const LayoutView = ({ children, loggedIn, loading, showFooter, showWhenLoading }: Props) => {
  const { pathname } = useLocation()

  const { secure } = pages.find((page: Page): boolean => {
    return page.pathRegExp.test(pathname)
  })!

  const showChildrenResult = (showWhenLoading || !loading) && (!secure || (loggedIn && secure))

  return (
    <>
      <Container className="layout-container">
        <ThemeSetter />
        <Toasts />
        <Row style={{ height: '70px' }}>
          <Header />
        </Row>
        {showChildrenResult ? (
          <Row style={{ minHeight: '50vh' }}>
            <Col style={{ padding: '15px' }}>{children}</Col>{' '}
          </Row>
        ) : null}
        {!loggedIn && !loading && secure && (
          <Row style={{ minHeight: '50vh' }}>
            <Col className="text-center">
              <h3 className="loading-header">
                <Login />
              </h3>
            </Col>
          </Row>
        )}
        {loading && secure && !showWhenLoading ? (
          <Row className="d-flex justify-content-center align-items-center" style={{ minHeight: '50vh' }}>
            <Col className="text-center">
              <Loading text="Logging in..." />
            </Col>
          </Row>
        ) : null}
      </Container>
      {showFooter ? <Footer /> : null}
    </>
  )
}
