import { type ReactNode, useEffect } from 'react'
// import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import type { Guild } from '../../../../models/Guild'
import type { Subscription } from '../../../../models/Subscription'
import type { TwitchIntegration } from '../../../../models/TwitchIntegration'
import type { User } from '../../../../models/User'
// import { canPerformActionWithSubscription, SubscriptionErrors } from '../../../../lib/subscription'

import { PageHeaderView as PageHeader } from '../../../PageHeader/PageHeaderView'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

import { NoPermissionsCard } from './components/NoPermissionsCard'
import { NoSubscriptionCard } from './components/NoSubscriptionCard'
import { NoIntegrationYetCard } from './components/NoIntegrationYetCard'

interface Props {
  canCreate: boolean
  load: (discordUserId: string) => void
  loading: boolean
  startOauthFlow: () => void

  currentUser?: User
  guild?: Guild
  subscription?: Subscription
  twitchIntegration?: TwitchIntegration
}

const renderContainer = (guildId: string, children: ReactNode) => (
  <Container>
    <PageHeader backButtonPath={`/dashboard/${guildId}`}>Twitch Integration</PageHeader>
    {children}
  </Container>
)

export const TwitchIntegrationView = ({ canCreate, currentUser, guild, load, loading, startOauthFlow, subscription, twitchIntegration }: Props) => {
  useEffect(() => {
    if (currentUser) {
      load(currentUser.discordId)
    }
  }, [currentUser, load])

  console.log('subscription ', subscription)

  if (loading) {
    return <Loading style={{ marginTop: '32vh' }} />
  }

  if (!guild?.hasBot) {
    return <NoBotInGuild guild={guild} />
  }

  if (!twitchIntegration) {
    return renderContainer(guild.id, <NoIntegrationYetCard startOauthFlow={startOauthFlow} />)
  }

  if (!subscription) {
    return renderContainer(guild.id, <NoSubscriptionCard guildId={guild.id} />)
  }

  if (!canCreate) {
    return renderContainer(guild.id, <NoPermissionsCard />)
  }

  return renderContainer(
    guild.id,
    <Row className="justify-content-center mt-3 text-center">
      <Col md={6} lg={4}>
        <Card>
          <Card.Body>Twitch Integration</Card.Body>
        </Card>
      </Col>
    </Row>
  )
}
