import { useForm } from 'react-hook-form'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'

import type { WelcomeMessage, WelcomeMessagePatch } from '../../../../../models/WelcomeMessage'

interface WelcomeMessageFormProps {
  onSubmit: (data: WelcomeMessagePatch) => void
  welcomeMessage: WelcomeMessage
}

export const WelcomeMessageFormView = ({ welcomeMessage, onSubmit }: WelcomeMessageFormProps) => {
  const {
    formState: { errors },
    handleSubmit,
    register
  } = useForm<WelcomeMessagePatch>({
    defaultValues: welcomeMessage
  })

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Container>
        <Row>
          <Col md={8} className="offset-md-2">
            <Form.Group className="mb-3">
              <Form.Label>Channel ID</Form.Label>
              <Form.Control type="text" {...register('channelId', { required: true, maxLength: 128 })} placeholder="Enter channel ID" />
              {errors.channelId && <Form.Text className="text-danger">Channel ID is required and must not exceed 128 characters</Form.Text>}
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Welcome Message</Form.Label>
              <Form.Control as="textarea" rows={4} {...register('message', { required: true, maxLength: 500 })} placeholder="Enter welcome message" />
              {errors.message && <Form.Text className="text-danger">Message is required and must not exceed 500 characters</Form.Text>}
            </Form.Group>

            <div className="text-center">
              <Button variant="primary" type="submit">
                Update Welcome Message
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}
