import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout'
import { Polls } from '../../../../components/Guilds/GuildDetail/Polls'

export const PollsView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <Polls guildId={guildId!} />
    </Layout>
  )
}
