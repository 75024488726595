export { canUpgradePlan, isInTrialPeriod, isSubscriptionActive, isSubscriptionNew } from '@rtninja/rtninja-backend-client'

export enum SubscriptionDurations {
  ONE_MONTH = '1-month',
  THREE_MONTHS = '3-months',
  SIX_MONTHS = '6-months',
  TWELVE_MONTHS = '12-months'
}

export const subscriptionData = [
  {
    name: 'basic',
    description: 'Basic Plan',
    details: [
      '3 Polls (At A Time)',
      '2 Reaction Roles',
      '3 Notifications',
      'Quote of the Day',
      'New Member Welcome Message',
      'Member Leaving Message',
      'Access to Commands'
    ],
    price: 3.99
  },
  {
    name: 'advanced',
    description: 'Advanced Plan',
    details: [
      '5 Polls (At A Time)',
      '5 Reaction Roles',
      '15 Notifications',
      'Quote of the Day',
      'New Member Welcome Message',
      'Member Leaving Message',
      '1 Simple Social Integration',
      'Access to Commands'
    ],
    price: 6.99
  },
  {
    name: 'ninja',
    description: 'Ninja Plan',
    details: [
      'Personalized Bot',
      '10 Polls (At A Time)',
      '10 Reaction Roles',
      '50 Notifications',
      'Quote of the Day',
      'New Member Welcome Message',
      'Member Leaving Message',
      '1 Advanced Social Integration',
      'Access to Commands'
    ],
    price: 10.99
  }
]

export const calculateMonthlyPrice = (plan: string, duration: string) => {
  let result = subscriptionData.find((item) => item.name === plan)!.price
  if (duration === SubscriptionDurations.THREE_MONTHS) {
    result *= 0.95
  } else if (duration === SubscriptionDurations.SIX_MONTHS) {
    result *= 0.9
  } else if (duration === SubscriptionDurations.TWELVE_MONTHS) {
    result *= 0.75
  }
  return result.toFixed(2)
}

export const calculateTotalPrice = (plan: string, duration: string) => {
  let result = parseFloat(calculateMonthlyPrice(plan, duration))
  if (duration === SubscriptionDurations.THREE_MONTHS) {
    result *= 3
  } else if (duration === SubscriptionDurations.SIX_MONTHS) {
    result *= 6
  } else if (duration === SubscriptionDurations.TWELVE_MONTHS) {
    result *= 12
  }
  return result.toFixed(2)
}
