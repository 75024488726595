import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import type { Guild } from '../../models/Guild'
import type { Subscription } from '../../models/Subscription'
import type { User, UserPatch } from '../../models/User'

import { calculateTotalPrice, isInTrialPeriod, isSubscriptionActive } from '../../lib/subscription'

import { NoBotInGuildView as NoBotInGuild } from '../Bots/NoBotInGuild/NoBotInGuildView'
import { PageHeaderView as PageHeader } from '../PageHeader'
import { Loading } from '../Loading'

import { RegisterOrUpdateEmailView as RegisterOrUpdateEmail } from './components/RegisterOrUpdateEmailView'
import { VerifyEmailView as VerifyEmail } from './components/VerifyEmailView'
import { PaymentCompleteView as PaymentComplete } from './components/PaymentComplete'
import { SubscriptionDetailsView as SubscriptionDetails } from './components/SubscriptionDetails'
import { Paypal } from './components/Paypal'
import { Stripe } from './components/Stripe'

interface Props {
  loading: boolean
  plan: string
  planDuration: string
  updateUser: (id: string, data: UserPatch) => void
  userLoading: boolean

  complete?: boolean
  guild?: Guild
  provider?: string
  subscription?: Subscription
  user?: User
}

export const CheckoutView = ({ complete, guild, loading, plan, planDuration, provider, subscription, user, updateUser, userLoading }: Props) => {
  const navigate = useNavigate()

  const [updateEmail, setUpdateEmail] = useState(false)

  const planDurationInMonths = parseInt(planDuration.split('-')[0])
  const expiresDate = new Date()
  const currentMonth = expiresDate.getMonth()
  const newMonth = currentMonth + planDurationInMonths

  expiresDate.setMonth(newMonth % 12)
  expiresDate.setFullYear(expiresDate.getFullYear() + Math.floor(newMonth / 12))

  const isTrial = subscription && isInTrialPeriod(subscription)
  const subscriptionActive = subscription && isSubscriptionActive(subscription)
  const userIsUpgradingPlan = subscriptionActive && (plan !== subscription?.plan || planDuration !== subscription?.planDuration)
  const total =
    subscription?.proratedAmountDue && +subscription.proratedAmountDue
      ? subscription.proratedAmountDue
      : !subscriptionActive
        ? calculateTotalPrice(plan, planDuration)
        : ''

  return loading ? (
    <Loading />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (!userIsUpgradingPlan && !isTrial && (complete || subscriptionActive)) || (isTrial && complete) ? (
    <PaymentComplete guildId={guild.id} isTrial={!!isTrial} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/subscription${guild ? `/${guild.id}` : ''}`} className="mb-2">
        Checkout
      </PageHeader>
      <Row className="justify-content-center mb-3">
        <Col md={6} lg={6} className="text-center">
          <SubscriptionDetails expiresDate={expiresDate} plan={plan} planDurationInMonths={planDurationInMonths} total={total} />
        </Col>
      </Row>
      {userLoading ? (
        <Loading text="Processing..." />
      ) : !user!.email || updateEmail ? (
        <RegisterOrUpdateEmail setUpdateEmail={setUpdateEmail} updateUser={updateUser} user={user!} />
      ) : user!.email && !user!.emailVerified ? (
        <VerifyEmail setUpdateEmail={setUpdateEmail} updateUser={updateUser} user={user!} />
      ) : !provider ? (
        <>
          <Row className="justify-content-center mb-3">
            <Col className="text-center">
              <h2 className="text-center mt-3">Please choose your preferred method of payment</h2>
            </Col>
          </Row>
          <Row className="justify-content-center mb-3">
            <Col md={3} lg={3} className="text-center">
              <Button
                size="lg"
                variant={!provider ? 'info' : provider === 'stripe' ? 'success' : 'danger'}
                onClick={() => navigate(`/checkout/${guild!.id}/${plan}/${planDuration}/stripe`)}
              >
                Credit Card (via Stripe)
              </Button>
            </Col>
            <Col md={3} lg={3} className="text-center">
              <Button
                size="lg"
                variant={!provider ? 'info' : provider === 'paypal' ? 'success' : 'danger'}
                onClick={() => navigate(`/checkout/${guild!.id}/${plan}/${planDuration}/paypal`)}
              >
                Paypal
              </Button>
            </Col>
          </Row>
        </>
      ) : provider === 'stripe' ? (
        <Stripe guildId={guild!.id} plan={plan} planDuration={planDuration} />
      ) : provider === 'paypal' ? (
        <Paypal guildId={guild!.id} plan={plan} planDuration={planDuration} />
      ) : null}
    </Container>
  )
}
