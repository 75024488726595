import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'

import type { Subscription } from '../../../../../models/Subscription'
import { isInTrialPeriod } from '../../../../../lib/subscription'

import { Loading } from '../../../../Loading'

const stripeKey =
  window.location.hostname === 'rt.ninja'
    ? 'production_key'
    : 'pk_test_51PZxkhRsPa7C4Ddw20tA3PZXgI7bCvVSqbpcwVRT8WhWo1CmixsAucPpf1jIftzGA3Pz7GupPizaz6vwvqAftQ4500K5wV1o5x'

const stripe = (window as any).Stripe?.(stripeKey) // eslint-disable-line

interface Props {
  subscription: Subscription
}

export const PaymentView = ({ subscription }: Props) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [processing, setProcessing] = useState(false)

  const isTrial = isInTrialPeriod(subscription)

  useEffect(() => {
    const options = {
      clientSecret: subscription.providerSecret
    }

    const elements = stripe.elements(options)
    const paymentElement = elements.create('payment')

    paymentElement.mount('#payment-element')
    paymentElement.on('ready', () => {
      setLoading(false)
    })

    const form = document.getElementById('payment-form')

    form?.addEventListener('submit', async (event) => {
      event.preventDefault()
      setProcessing(true)

      const { error } = await stripe[isTrial ? 'confirmSetup' : 'confirmPayment']({
        elements,
        redirect: 'if_required'
      })

      if (error) {
        const messageContainer = document.querySelector('#error-message')
        if (messageContainer) {
          messageContainer.textContent = error.message || 'An error occurred while processing the payment.'
        }
        setProcessing(false)
      } else {
        navigate(`${location.pathname}/complete`)
      }
    })
  }, [isTrial, location.pathname, navigate, subscription.providerSecret])

  return (
    <>
      {loading ? <Loading text="Loading Payment Details..." /> : null}
      <form id="payment-form" className="d-flex flex-column align-items-center" style={{ display: loading ? 'none' : 'inline' }}>
        <div id="payment-element" className="w-75 mb-3"></div>
        <div id="error-message" className="w-75 text-center text-danger mb-3"></div>
        <Button type="submit" size="lg" disabled={processing}>
          {isTrial ? (processing ? 'Setting Up Payment Method' : 'Setup Payment Method') : processing ? 'Processing Payment' : 'Subscribe'}
        </Button>
      </form>
    </>
  )
}
