import { selectGuildData, selectPermissionsDataForGuild } from '../redux/selectors'
import type { RootState } from '../redux/store'
import type { PermissionNames } from './permissions'
import { hasOverlap } from './util'

export const isAuthorized = (permissionName: PermissionNames, guildId: string, loading: boolean, state: RootState): boolean => {
  const guild = selectGuildData(guildId, state)
  const permissions = selectPermissionsDataForGuild(guildId, state)
  const permission = permissions.find((permission) => {
    return permission.guildId === guildId && permission.name === permissionName
  })

  return loading || !guild ? false : guild.owner || hasOverlap(guild.userRoles!, permission!.roles)
}
