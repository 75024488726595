import Container from 'react-bootstrap/Container'

import type { Guild } from '../../../../models/Guild'
import type { Notification } from '../../../../models/Notification'

import { PageHeaderView as PageHeader } from '../../../PageHeader/PageHeaderView'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

interface Props {
  loading: boolean

  guild?: Guild
  notifications?: Notification
}

export const NotificationsView = ({ loading, guild, notifications }: Props) => {
  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>Notifications</PageHeader>
    </Container>
  )
}
