import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { DailyQuote, DailyQuoteData, DailyQuotePatch } from '../../../models/DailyQuote'

import { Api } from '../../../lib/api'
import { mergeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: DailyQuote[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

type ICreateParams = {
  data: DailyQuoteData
  params?: Params
}

interface IPatchParams {
  id: string
  data: DailyQuotePatch
  params?: Params
}

export const findAsync = createAsyncThunk('dailyQuotes/find', async (params: Params | undefined, { dispatch }): Promise<DailyQuote[]> => {
  try {
    const { data } = await Api.find('daily-quotes', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting dailyQuotes: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('dailyQuotes/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<DailyQuote | void> => {
  try {
    return await Api.create('daily-quotes', data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error creating dailyQuote: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('dailyQuotes/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<DailyQuote> => {
  try {
    return await Api.patch('daily-quotes', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

const handleFulfilled = (state: DataState, action: PayloadAction<DailyQuote | DailyQuote[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const dailyQuotesSlice = createSlice({
  name: 'dailyQuotes',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<DailyQuote | DailyQuote[]>) => {
      state.data = mergeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = dailyQuotesSlice.actions

export default dailyQuotesSlice.reducer
