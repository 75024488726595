import { createSlice, type PayloadAction } from '@reduxjs/toolkit'

export interface ConnectionState {
  connected: boolean
}

const initialState: ConnectionState = {
  connected: false
}

export const connectionSlice = createSlice({
  name: 'connection',
  initialState,
  reducers: {
    setConnected: (state, action: PayloadAction<boolean>) => {
      state.connected = action.payload
    }
  }
})

export default connectionSlice.reducer

export const { setConnected } = connectionSlice.actions
