import { Layout } from '../../components/Layout'
import { Guilds } from '../../components/Guilds'

import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const DashboardView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Servers Dashboard</PageHeader>
      <Guilds />
    </Layout>
  )
}
