import { Layout } from '../../components/Layout'
import { SupportContactLinkView as SupportContactLink } from '../../components/SupportContactLink'
import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const PrivacyPolicyView = () => {
  return (
    <Layout showFooter>
      <PageHeader>Privacy Policy</PageHeader>

      <p>
        Welcome to RT Ninja, a Discord bot and dashboard service dedicated to providing a secure, private, and efficient user experience. Your privacy is of
        paramount importance to us, and we are committed to protecting it through our compliance with this policy.
      </p>

      <h5>Information We Collect</h5>

      <p>
        We collect information that you provide directly to us when you use our services. This may include your Discord username, email address, and any other
        information you choose to provide. We do not collect personal information beyond what is necessary to offer our services.
      </p>

      <h5>How We Use Your Information</h5>

      <p>We use the information we collect about you in the following ways:</p>
      <ul>
        <li>To allow you to log in to the dashboard</li>
        <li>To know what Discord servers you are a member of</li>
        <li>For security purposes, to detect, investigate, and prevent fraudulent transactions and other illegal activities</li>
        <li>To process payments for subscription services</li>
      </ul>

      <h2>Information Sharing and Disclosure</h2>
      <ul>
        <li>No Third-Party Ads: We do not display third-party advertisements on our platform or share your information with advertisers.</li>
        <li>Not Selling Data: We do not sell your personal information to third parties.</li>
        <li>
          Legal Requirements: We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court
          or a government agency).
        </li>
      </ul>

      <h2>Payment Processors</h2>

      <h3>Stripe Payments</h3>

      <p>
        We use Stripe as one of our payment processors. Stripe collects information on customer interactions with payment elements to provide services to us,
        prevent fraud, and improve its services. This includes using cookies and IP addresses to identify which payment elements a customer interacted with
        during a single checkout session.
      </p>
      <p>
        By using our services and making payments through Stripe, you agree to Stripe’s collection and use of your information as described in their{' '}
        <a href="https://stripe.com/privacy">Privacy Policy</a>.
      </p>
      <p>
        You are responsible for disclosing and obtaining all rights and consents necessary for Stripe to use data in these ways. For more information, visit
        Stripe's <a href="https://stripe.com/privacy-center/legal">Privacy Center</a>.
      </p>

      <h3>PayPal Payments</h3>

      <p>
        We also use PayPal to process payments. PayPal collects information on customer interactions with their payment elements to provide services to us,
        prevent fraud, and improve its services. This includes using cookies and IP addresses to identify which payment elements a customer interacted with
        during a single checkout session.
      </p>
      <p>
        By using our services and making payments through PayPal, you agree to PayPal's collection and use of your information as described in their{' '}
        <a href="https://www.paypal.com/myaccount/privacy/privacyhub">Privacy Policy</a>.
      </p>
      <p>
        You are responsible for disclosing and obtaining all rights and consents necessary for PayPal to use data in these ways. For more information, visit
        PayPal's <a href="https://www.paypal.com/myaccount/privacy/privacyhub">Privacy Center</a>.
      </p>

      <h2>Data Security</h2>

      <p>
        We implement a variety of security measures designed to maintain the safety of your personal information when you enter, submit, or access your personal
        information. These measures include advanced encryption and firewall technology.
      </p>

      <h2>Your Data Rights</h2>

      <p>
        You have the right to access, correct, or delete any personal information that we have collected about you. To exercise these rights, please{' '}
        <SupportContactLink />.
      </p>

      <h2>Changes to Our Privacy Policy</h2>

      <p>
        We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page. You are advised
        to review this privacy policy periodically for any changes.
      </p>

      <p>
        Contact Us If you have any questions about this privacy policy, please <SupportContactLink />.
      </p>

      <p className="text-end">Last updated: May 9th, 2024</p>
    </Layout>
  )
}
