import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { canUpgradePlan, isInTrialPeriod, isSubscriptionActive } from '../../../../lib/subscription'
import { capitalizeFirstLetter } from '../../../../lib/util'

import type { Guild } from '../../../../models/Guild'
import type { Subscription } from '../../../../models/Subscription'

import { Loading } from '../../../Loading'

import { PaymentView as Payment } from './components/PaymentView'

interface Props {
  createSubscription: (plan: string, planDuration: string) => void
  loading: boolean
  plan: string
  planDuration: string
  previewUpdateSubscription: (id: string, plan: string, planDuration: string) => void
  subscriptionModifying: boolean
  updateSubscription: (id: string, plan: string, planDuration: string) => Promise<void>

  guild?: Guild
  subscription?: Subscription
  subscriptionErrorMessage?: string
}

export const StripeView = ({
  createSubscription,
  loading,
  plan,
  planDuration,
  previewUpdateSubscription,
  subscription,
  subscriptionModifying,
  subscriptionErrorMessage,
  updateSubscription
}: Props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [upgradePlanError, setUpgradePlanError] = useState<string | undefined>()
  const [subscriptionCreateSubmitted, setSubscriptionCreateSubmitted] = useState(false)
  const [canChargeForUpgrade, setCanChargeForUpgrade] = useState(false)
  const [canUpgradeTrialPlan, setCanUpgradeTrialPlan] = useState(false)
  const [processingPayment, setProcessingPayment] = useState(false)

  const subscriptionIsActive = subscription && isSubscriptionActive(subscription)
  const subscriptionIsInTrial = subscription && isInTrialPeriod(subscription)

  useEffect(() => {
    if (!subscriptionCreateSubmitted && !subscriptionModifying && !subscriptionErrorMessage) {
      if (subscription) {
        const canUpgradePlanError = canUpgradePlan(subscription.plan, subscription.planDuration, plan, planDuration)
        if (subscriptionIsActive && canUpgradePlanError) {
          setUpgradePlanError(canUpgradePlanError)
        } else if (subscriptionIsInTrial) {
          setCanUpgradeTrialPlan(true)
        } else if (subscriptionIsActive) {
          previewUpdateSubscription(subscription.id, plan, planDuration)
          setCanChargeForUpgrade(true)
        }
      } else {
        createSubscription(plan, planDuration)
      }
      setSubscriptionCreateSubmitted(true)
    }
  }, [
    createSubscription,
    plan,
    planDuration,
    previewUpdateSubscription,
    setSubscriptionCreateSubmitted,
    subscription,
    subscriptionCreateSubmitted,
    subscriptionErrorMessage,
    subscriptionIsActive,
    subscriptionIsInTrial,
    subscriptionModifying,
    updateSubscription
  ])

  const handleChargeCardOnFile = async () => {
    try {
      setProcessingPayment(true)
      await updateSubscription(subscription!.id, plan, planDuration)
      navigate(`${location.pathname}/complete`)
    } catch (error: unknown) {
      setProcessingPayment(false)
    }
  }

  return loading ? (
    <Loading />
  ) : processingPayment ? (
    <Loading text="Processing Payment..." />
  ) : !subscription || subscriptionModifying ? (
    <Loading text="Preparing Subscription..." />
  ) : upgradePlanError ? (
    <h3 className="text-center text-danger">{upgradePlanError}</h3>
  ) : canChargeForUpgrade || canUpgradeTrialPlan ? (
    <>
      <Row className="justify-content-center">
        <Col md={8} lg={8} className="text-center">
          {plan && subscription.plan !== plan && planDuration && subscription.planDuration !== planDuration ? (
            <h2 className="text-center mb-4">
              Upgrade plan from {capitalizeFirstLetter(subscription.plan)} to {capitalizeFirstLetter(plan)} and upgrade plan duration from{' '}
              {subscription.planDuration.split('-')[0]} month{subscription.planDuration.split('-')[0] === '1' ? '' : 's'} to {planDuration.split('-')[0]} month
              {planDuration.split('-')[0] === '1' ? '' : 's'}
            </h2>
          ) : planDuration && subscription.planDuration !== planDuration ? (
            <h2 className="text-center mb-4">
              Upgrade plan duration from {subscription.planDuration.split('-')[0]} month{subscription.planDuration.split('-')[0] === '1' ? '' : ''} to{' '}
              {planDuration.split('-')[0]} month{planDuration.split('-')[0] === '1' ? '' : 's'}
            </h2>
          ) : plan && subscription.plan !== plan ? (
            <h2 className="text-center mb-4">
              Upgrade plan from {capitalizeFirstLetter(subscription.plan)} to {capitalizeFirstLetter(plan)}
            </h2>
          ) : null}
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={6} lg={6} className="text-center">
          <Button size="lg" className="mx-auto" onClick={handleChargeCardOnFile}>
            {subscriptionIsInTrial ? 'Upgrade plan' : 'Charge card on file'}
          </Button>
        </Col>
      </Row>
    </>
  ) : (
    <Payment subscription={subscription} />
  )
}
