import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'

import { ThemePicker } from '../../../../ThemePicker'
import { Modal } from '../../../../Modal'
import { Loading } from '../../../../Loading'
import { NoBotInGuildView } from '../../../../Bots/NoBotInGuild'

import type { Guild } from '../../../../../models/Guild'
import type { Poll, PollData } from '../../../../../models/Poll'
import type { User } from '../../../../../models/User'

import { PollFormView as PollForm } from './components/PollForm'

interface Props {
  loading: boolean
  channelDeleted: () => void
  currentUser?: User
  currentThemeType: string
  guild?: Guild
  guildId: string
  channelId: string
  addPoll: (data: PollData) => Promise<Poll | undefined>
  openModal: (modalName: string) => void
}

let addPollData: PollData

export const AddPollView = ({ channelDeleted, loading, currentUser, currentThemeType, guild, guildId, channelId, addPoll, openModal }: Props) => {
  const navigate = useNavigate()
  const channel = guild?.channels?.find((channel: { id: string }) => {
    return channelId === channel.id
  })

  useEffect(() => {
    if (!loading && guild && guild.channels && !channel) {
      channelDeleted()
      navigate(`/dashboard/${guild.id}/polls`)
    }
  }, [channel, channelDeleted, guild, loading, navigate])

  const handleConfirmCreatePoll = async () => {
    const poll = await addPoll(addPollData)
    if (poll) {
      navigate(`/dashboard/${guildId}/polls/${poll.id}`)
    }
  }

  const handleOnSubmit = (data: PollData): void => {
    addPollData = data
    openModal('confirmCreatePoll')
  }

  return !currentUser || loading ? (
    <Loading text="Creating poll..." style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuildView guild={guild} />
  ) : (
    <Container>
      <Row>
        <Col className="d-flex align-items-center position-relative">
          <div className="w-100 text-center mb-3">
            <h2>New Poll</h2>
            {channel?.name ? <h4>in "{channel.name}" channel</h4> : null}
          </div>
          <ThemePicker style={{ position: 'absolute', right: 0 }} />
        </Col>
      </Row>
      <Row style={{ width: '60%', margin: '0 auto' }}>
        <Col>
          <PollForm onSubmit={handleOnSubmit} creatorId={currentUser?.id} channelId={channelId} guildId={guildId} currentThemeType={currentThemeType} />
        </Col>
      </Row>
      <Modal modalName="confirmCreatePoll" title="Confirm Create Poll" accept={handleConfirmCreatePoll} saveButtonText="Confirm">
        <h1 className="text-danger text-center">Warning</h1>
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-x-circle text-danger me-3 fs-1" />
          Are you sure you wish to create this poll? It cannot be edited once created.
        </div>
      </Modal>
    </Container>
  )
}
