import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { PageHeaderView as PageHeader } from '../../PageHeader'
import type { Guild } from '../../../models/Guild'

import { Loading } from '../../Loading'

import { NoBotInGuildView as NoBotInGuild } from '../../Bots/NoBotInGuild'
import { FeatureCardView as FeatureCard } from './components/FeatureCardView'

const features = [
  'reactionRoles',
  'welcomeMessage',
  'leavingMessage',
  'polls',
  'notifications',
  'dailyQuotes',
  'personalizedBot',
  'permissions',
  'twitchIntegration',
  'tiktokIntegration',
  'youtubeIntegration'
]

interface Props {
  canEditPermissions: boolean
  guild?: Guild
  guildId: string
  loading: boolean
}

export const GuildDetailView = ({ canEditPermissions, guild, loading }: Props) => {
  const authorizedFeatures = features.filter((feature) => {
    if (feature === 'permissions') {
      return canEditPermissions
    }

    return true
  })

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !loading && guild && !guild.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath="/dashboard">Server Features</PageHeader>
      <Row>
        {authorizedFeatures.map((feature: string) => (
          <Col key={feature} md={4} className="mb-4">
            <FeatureCard feature={feature} guildId={guild?.id || ''} />
          </Col>
        ))}
      </Row>
    </Container>
  )
}
