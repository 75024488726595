import { useEffect } from 'react'

export const OauthView = () => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get('code')
    const origin = sessionStorage.getItem('oauthOrigin')
    sessionStorage.removeItem('oauthOrigin')
    const state = params.get('state')
    const oauthState = sessionStorage.getItem('oauthState')
    sessionStorage.removeItem('oauthState')

    if (code && origin === window.location.origin && state === oauthState) {
      window.opener?.postMessage({ status: 'success', code }, origin)
      setTimeout(() => window.close(), 250)
    } else {
      window.close()
    }
  }, [])

  return <></>
}
