import Container from 'react-bootstrap/Container'

import type { Guild } from '../../../../models/Guild'
import type { LeavingMessage, LeavingMessageData, LeavingMessagePatch } from '../../../../models/LeavingMessage'

import { PageHeaderView as PageHeader } from '../../../PageHeader/PageHeaderView'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

import { LeavingMessageFormView as LeavingMessageForm } from './components/LeavingMessageFormView'

interface Props {
  createLeavingMessage: (data: LeavingMessageData) => void
  loading: boolean
  updateLeavingMessage: (id: string, data: LeavingMessagePatch) => void

  guild?: Guild
  leavingMessage?: LeavingMessage
}

export const LeavingMessageView = ({ createLeavingMessage, loading, guild, leavingMessage, updateLeavingMessage }: Props) => {
  const handleSubmit = (data: LeavingMessagePatch) => {
    if (leavingMessage) {
      updateLeavingMessage(leavingMessage.id, data)
    } else {
      createLeavingMessage({ guildId: guild!.id, ...data } as LeavingMessageData)
    }
  }

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>Leaving Message</PageHeader>
      <LeavingMessageForm onSubmit={handleSubmit} channels={guild?.channels} leavingMessage={leavingMessage} />
    </Container>
  )
}
