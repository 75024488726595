import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Table from 'react-bootstrap/Table'

import { formatDateShort } from '../../../../lib/util'

import type { Guild } from '../../../../models/Guild'
import type { ReactionRole } from '../../../../models/ReactionRole'

import { Loading } from '../../../Loading/Loading'
import { PageHeaderView as PageHeader } from '../../../PageHeader'
import { NoBotInGuildView } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

interface Props {
  canCreateReactionRoles: boolean
  loading: boolean
  reactionRoles: ReactionRole[]

  guild?: Guild

  load: () => void
}

export const ReactionRolesView = ({ canCreateReactionRoles, guild, load, loading, reactionRoles }: Props) => {
  const navigate = useNavigate()

  useEffect(load, [load])

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuildView guild={guild} />
  ) : !canCreateReactionRoles ? (
    <div>You do not have permission to create reaction roles</div>
  ) : (
    <Container>
      <PageHeader
        backButtonPath={`/dashboard/${guild?.id}`}
        rightButton={
          <Button variant="success" className="text-nowrap" onClick={() => navigate(`/dashboard/${guild?.id}/reaction-roles/add`)}>
            Create Reaction Role
          </Button>
        }
      >
        Reaction Roles
      </PageHeader>
      {!reactionRoles.length ? (
        <h2 className="text-center" style={{ marginTop: '32vh' }}>
          There are no reaction roles in this server yet.
        </h2>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="col-4">Name</th>
              <th className="col-6">Description</th>
              <th className="col-2 text-center">Created At</th>
            </tr>
          </thead>
          <tbody>
            {reactionRoles.map((reactionRole, index) => (
              <tr key={index} className="cursor-pointer" onClick={() => navigate(`/dashboard/${reactionRole.guildId}/reaction-roles/${reactionRole.id}`)}>
                <td className="col-4">{reactionRole.name}</td>
                <td className="col-6">{reactionRole.description}</td>
                <td className="col-2 text-center">{formatDateShort(reactionRole.createdAt)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  )
}
