import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Dropdown from 'react-bootstrap/Dropdown'
import Row from 'react-bootstrap/Row'

import { PageHeaderView as PageHeader } from '../PageHeader'
import type { Guild } from '../../models/Guild'

import { calculateTotalPrice, subscriptionData } from '../../lib/subscription'

import { Loading } from '../Loading'

import { SubscriptionCardView as SubscriptionCard } from './components/SubscriptionCardView'

import { durationOptions, type DurationOptionType } from './shared'

import './styles.css'

interface Props {
  guilds: Guild[]
  loading: boolean

  guild?: Guild
  guildId?: string
}

export const SubscriptionView = ({ guild, guilds, loading }: Props) => {
  const navigate = useNavigate()

  const [selectedCard, setSelectedCard] = useState<number | null>(null)
  const [selectedDuration, setSelectedDuration] = useState<DurationOptionType>('Select an option')

  const handleSelectGuild = (guildSelection: Guild) => {
    navigate(`/subscription/${guildSelection.id}`)
  }

  const subscribeButtonDisabled = selectedDuration === 'Select an option' || selectedCard === null || !guild

  const totalPrice = selectedCard !== null ? calculateTotalPrice(subscriptionData[selectedCard].name, selectedDuration) : 0
  const checkoutPath = selectedCard !== null ? `/checkout/${guild?.id}/${subscriptionData[selectedCard].name}/${selectedDuration}` : ''

  return loading ? (
    <Loading />
  ) : (
    <Container>
      <PageHeader className="mb-2">Subscription</PageHeader>
      <Row>
        {subscriptionData.map((data, index) => (
          <Col key={data.name} md={4} className="mb-4">
            <SubscriptionCard data={data} index={index} selectedCard={selectedCard} setSelectedCard={setSelectedCard} selectedDuration={selectedDuration} />
          </Col>
        ))}
      </Row>
      <Row>
        <Col className="text-center">
          Server to subscribe with:
          <Dropdown>
            <Dropdown.Toggle variant={guild ? 'success' : 'info'}>{guild?.name || 'Select a Discord Server'}</Dropdown.Toggle>

            <Dropdown.Menu>
              {guilds.map((guild) => (
                <Dropdown.Item key={guild.id} onClick={() => handleSelectGuild(guild)}>
                  {guild.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col className="text-center">
          Subscription Duration:
          <Dropdown>
            <Dropdown.Toggle variant={selectedDuration === 'Select an option' ? 'info' : 'success'}>{selectedDuration}</Dropdown.Toggle>

            <Dropdown.Menu>
              {Object.entries(durationOptions).map(([duration, value]) => (
                <Dropdown.Item key={duration} onClick={() => setSelectedDuration(value)}>
                  {duration}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-center">
          <Button
            disabled={subscribeButtonDisabled}
            variant={subscribeButtonDisabled ? 'danger' : 'success'}
            className="subscribe-button"
            onClick={() => navigate(checkoutPath)}
          >
            Checkout{!subscribeButtonDisabled && totalPrice ? ` - $${totalPrice}` : null}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
