import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'

import { Loading } from '../../../../Loading'
import { Modal } from '../../../../Modal'
import { NoBotInGuildView } from '../../../../Bots/NoBotInGuild'

import type { Guild } from '../../../../../models/Guild'
import type { ReactionRole } from '../../../../../models/ReactionRole'
import type { ReactionRoleOption } from '../../../../../models/ReactionRoleOption'

interface Props {
  canRemoveReactionRole: boolean
  loading: boolean

  guild?: Guild
  reactionRole?: ReactionRole
  reactionRoleOptions?: ReactionRoleOption[]

  load: () => void
  openModal: (modalName: string) => void
  removeReactionRole: () => Promise<void>
}

export const ReactionRoleDetailView = ({
  canRemoveReactionRole,
  guild,
  loading,
  reactionRole,
  reactionRoleOptions,
  load,
  openModal,
  removeReactionRole
}: Props) => {
  const navigate = useNavigate()

  useEffect(load, [load])

  const handleConfirmRemoveReactionRole = async (): Promise<void> => {
    await removeReactionRole()
    navigate(`/dashboard/${reactionRole?.guildId}/reaction-roles`)
  }

  return loading ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuildView guild={guild} />
  ) : (
    <Container>
      <Row className="mb-3">
        <Col md={2}>
          <Button id="poll-detail-view-back-button" onClick={() => navigate(`/dashboard/${reactionRole?.guildId}/reaction-roles`)}>
            Back
          </Button>
        </Col>
        <Col md={{ offset: 1, span: 6 }}>
          <Card>
            <Card.Header>
              <h2 className="text-center">Reaction Role Details</h2>
            </Card.Header>
            <Card.Body>
              <Card.Title>{reactionRole?.name}</Card.Title>
              {reactionRole?.description ? <Card.Text>{reactionRole.description}</Card.Text> : null}
            </Card.Body>
          </Card>
        </Col>
        <Col className="text-end" md={{ offset: 1, span: 2 }}>
          {canRemoveReactionRole ? (
            <Button variant="danger" className="text-nowrap" onClick={() => openModal('confirmRemoveReactionRole')}>
              Remove Reaction Role
            </Button>
          ) : null}
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={{ offset: 3, span: 6 }}></Col>
      </Row>
      {(reactionRoleOptions || []).map((reactionRoleOption) => (
        <Row key={reactionRoleOption.id}>
          <Col md={{ offset: 3, span: 4 }}>{guild.roles?.find((role) => role.id === reactionRoleOption.roleId)?.name}</Col>
          <Col md={2} className="text-center">
            {reactionRoleOption.emoji}
          </Col>
        </Row>
      ))}
      <Modal modalName="confirmRemoveReactionRole" title="Confirm Remove Reaction Role" accept={handleConfirmRemoveReactionRole} saveButtonText="Remove">
        <h1 className="text-danger text-center">Warning</h1>
        <div className="d-flex justify-content-center align-items-center">
          <i className="bi bi-x-circle text-danger me-3 fs-1" />
          Are you sure you wish to remove this reaction role? This action cannot be undone.
        </div>
      </Modal>
    </Container>
  )
}
