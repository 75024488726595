import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { YoutubeIntegration, YoutubeIntegrationData, YoutubeIntegrationPatch } from '../../../models/YoutubeIntegration'

import { Api } from '../../../lib/api'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean

  data?: YoutubeIntegration
  errorMessage?: string
}

const initialState: DataState = {
  loading: false
}

interface ICreateParams {
  id: string
  data: YoutubeIntegrationData

  params?: Params
}

interface IPatchParams {
  id: string
  data: YoutubeIntegrationPatch

  params?: Params
}

export const findAsync = createAsyncThunk('youtube-integrations/find', async (params: Params | undefined, { dispatch }): Promise<YoutubeIntegration> => {
  try {
    const { data } = await Api.find('youtube-integrations', params)
    return data[0]
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting youtube-integrations: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk(
  'youtube-integrations/create',
  async ({ data, params }: ICreateParams, { dispatch }): Promise<YoutubeIntegration> => {
    try {
      return await Api.create('youtube-integrations', data, params)
    } catch (e: unknown) {
      dispatch(createToast({ type: 'error', message: `Error getting youtube-integrations: ${(e as Error).message}` }))
      throw e
    }
  }
)

export const patchAsync = createAsyncThunk(
  'youtube-integrations/patch',
  async ({ id, data, params }: IPatchParams, { dispatch }): Promise<YoutubeIntegration> => {
    try {
      return await Api.patch('youtube-integrations', id, data, params)
    } catch (e: unknown) {
      dispatch(createToast({ type: 'error', message: `Error updating youtube-integration: ${(e as Error).message}` }))
      throw e
    }
  }
)

const handleFulfilled = (state: DataState, action: PayloadAction<YoutubeIntegration>) => {
  if (action.payload) {
    state.data = Object.assign(state.data || {}, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const youtubeIntegrationsSlice = createSlice({
  name: 'youtube-integrations',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<YoutubeIntegration>) => {
      state.data = Object.assign(state.data || {}, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state) => {
        delete state.errorMessage
        delete state.data
        state.loading = true
      })
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = youtubeIntegrationsSlice.actions

export default youtubeIntegrationsSlice.reducer
