import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { Notification, NotificationData, NotificationPatch } from '../../../models/Notification'

import { Api } from '../../../lib/api'
import { mergeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: Notification[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

type ICreateParams = {
  data: NotificationData
  params?: Params
}

interface IPatchParams {
  id: string
  data: NotificationPatch
  params?: Params
}

interface IRemoveParams {
  id: string
  params?: Params
}

export const findAsync = createAsyncThunk('notifications/find', async (params: Params | undefined, { dispatch }): Promise<Notification[]> => {
  try {
    const { data } = await Api.find('notifications', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting notifications: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('notifications/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<Notification | void> => {
  try {
    return await Api.create('notifications', data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error creating notification: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('notifications/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<Notification> => {
  try {
    return await Api.patch('notifications', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

export const removeAsync = createAsyncThunk('notifications/remove', async (id: string, { dispatch }): Promise<string> => {
  try {
    await Api.remove('notifications', id)
    dispatch(createToast({ type: 'success', message: 'Notification removed successfully' }))
    return id
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error deleting notification: ${(e as Error).message}` }))
    throw e
  }
})

const handleFulfilled = (state: DataState, action: PayloadAction<Notification | Notification[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<Notification | Notification[]>) => {
      state.data = mergeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = notificationsSlice.actions

export default notificationsSlice.reducer
