import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout'
import { TwitchIntegration } from '../../../../components/Guilds/GuildDetail/TwitchIntegration'

export const TwitchIntegrationView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <TwitchIntegration guildId={guildId!} />
    </Layout>
  )
}
