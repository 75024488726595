import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../redux/store'
import {
  selectBotsLoading,
  selectCurrentUser,
  selectGuildData,
  selectGuildsLoading,
  selectSubscriptionsDataForGuild,
  selectSubscriptionsLoading,
  selectUsersPatching
} from '../../redux/selectors'

import { patchAsync as patchUserAsync } from '../../redux/features/users/usersSlice'

import type { UserPatch } from '../../models/User'

import { CheckoutView } from './CheckoutView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectSubscriptionsLoading(state)

  return {
    guild: selectGuildData(ownProps.guildId!, state),
    loading,
    subscription: selectSubscriptionsDataForGuild(ownProps.guildId!, state),
    user: selectCurrentUser(state),
    userLoading: selectUsersPatching(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  updateUser: (id: string, data: UserPatch) => dispatch(patchUserAsync({ id, data }))
})

export const Checkout = connect(mapStateToProps, mapDispatchToProps)(CheckoutView)
