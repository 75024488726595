import { type ReactNode } from 'react'
import { Controller, type Control } from 'react-hook-form'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import DatePicker from 'react-datepicker'

interface Props {
  value: Date
  onChange: (date: Date | null) => void
  field?: string
  className?: string
  control?: Control<any> // eslint-disable-line
  minDate?: Date
  showTimeSelect?: boolean
  dateFormat?: string
  label?: ReactNode
  colSize?: number
  inline?: boolean
  errorMessage?: string
}

export const DatePickerView = ({
  value,
  onChange,
  field,
  className,
  control,
  minDate,
  showTimeSelect,
  dateFormat,
  label,
  colSize,
  inline,
  errorMessage
}: Props) => {
  const DatePickerComponent =
    field && control ? (
      <Controller
        name={field}
        control={control}
        render={({ field }) => (
          <DatePicker
            className={className}
            selected={value}
            onChange={(date: Date | null) => {
              if (date) {
                field.onChange(date)
                onChange(date)
              }
            }}
            showTimeSelect={showTimeSelect}
            dateFormat={dateFormat}
            minDate={minDate}
          />
        )}
      />
    ) : (
      <DatePicker className={className} selected={value} onChange={onChange} showTimeSelect={showTimeSelect} dateFormat={dateFormat} minDate={minDate} />
    )
  return inline ? (
    DatePickerComponent
  ) : (
    <Form.Group as={Row}>
      {label ? (
        <Form.Label column sm="2" htmlFor={`rtn-role-form-${field}`}>
          {label}
        </Form.Label>
      ) : null}
      <Col sm={colSize}>
        {DatePickerComponent}
        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
      </Col>
    </Form.Group>
  )
}
