import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../../redux/store'
import {
  selectGuildData,
  selectGuildsLoading,
  selectPollData,
  selectPollOptionsDataForPoll,
  selectPollVotesDataForPoll,
  selectPollOptionsLoading,
  selectPollVotesLoading,
  selectPollsLoading,
  selectCurrentUser
} from '../../../../../redux/selectors'
import { findAsync as findPollsAsync, removeAsync as removePollAsync, patchAsync as patchPollAsync } from '../../../../../redux/features/polls/pollsSlice'
import { findAsync as findPollOptionsAsync } from '../../../../../redux/features/pollOptions/pollOptionsSlice'
import { findAsync as findPollVotesAsync } from '../../../../../redux/features/pollVotes/pollVotesSlice'
import { closeAll, open } from '../../../../../redux/features/modal/modalSlice'
import type { PollPatch } from '../../../../../models/Poll'

import { PermissionNames } from '../../../../../lib/permissions'
import { isAuthorized } from '../../../../../lib/authorize'

import { PollDetailView } from './PollDetailView'

interface OwnProps {
  guildId: string
  pollId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const currentUser = selectCurrentUser(state)
  const poll = selectPollData(ownProps.pollId, state)
  const loading = selectGuildsLoading(state) || selectPollsLoading(state) || selectPollOptionsLoading(state) || selectPollVotesLoading(state)

  const userIsOwner = currentUser?.id === poll?.creatorId
  const canClosePoll = userIsOwner || isAuthorized(PermissionNames.CLOSE_OTHER_POLLS, ownProps.guildId, loading, state)
  const canRemovePoll = userIsOwner || isAuthorized(PermissionNames.REMOVE_OTHER_POLLS, ownProps.guildId, loading, state)

  return {
    canClosePoll,
    canRemovePoll,
    guild: selectGuildData(ownProps.guildId, state),
    loading,
    poll,
    pollOptions: selectPollOptionsDataForPoll(ownProps.pollId, state),
    pollVotes: selectPollVotesDataForPoll(ownProps.pollId, state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  load: (): void => {
    dispatch(findPollsAsync({ query: { id: ownProps.pollId } }))
    dispatch(findPollOptionsAsync({ query: { pollId: ownProps.pollId } }))
    dispatch(findPollVotesAsync({ query: { pollId: ownProps.pollId } }))
  },
  openModal: (modalName: string): void => {
    dispatch(open(modalName))
  },
  patchPoll: (data: PollPatch): void => {
    dispatch(patchPollAsync({ id: ownProps.pollId, data }))
  },
  removePoll: async (): Promise<void> => {
    dispatch(closeAll())
    await dispatch(removePollAsync(ownProps.pollId))
  }
})

export const PollDetail = connect(mapStateToProps, mapDispatchToProps)(PollDetailView)
