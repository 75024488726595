import { Link } from 'react-router-dom'
import { Container, Row, Col } from 'react-bootstrap'
import { SupportContactLinkView as SupportContactLink } from '../../../SupportContactLink/SupportContactLinkView'

export const Footer = () => {
  return (
    <Container fluid className="footer mt-5">
      <Container>
        <Row>
          <Col md={4} className="py-3">
            <h5>RT Ninja Bot</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/dashboard">Dashboard</Link>
              </li>
              <li>
                <Link to="/subscription">Subscribe</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="py-3">
            <h5>Legal</h5>
            <ul className="list-unstyled">
              <li>
                <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>
              </li>
              <li>
                <Link to="/cookie-policy">Cookie Policy</Link>
              </li>
              <li>
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li>
                <Link to="/security-policy">Security Policy</Link>
              </li>
              <li>
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </li>
            </ul>
          </Col>
          <Col md={4} className="py-3">
            <h5>Contact</h5>
            <ul className="list-unstyled">
              <li>
                <i className="bi bi-envelope" style={{ margin: '0 6px 0 0' }}></i>
                <SupportContactLink text="Contact Support" />
              </li>
              <li>
                <i className="bi bi-discord" style={{ margin: '0 6px 0 0' }}></i>
                <a href="https://discord.gg/YkBZnGBuyf" rel="nofollow noreferrer noopener">
                  Join the Discord
                </a>
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col className="text-end py-3">
            <small>© {new Date().getFullYear()} RT Ninja - All Rights Reserved.</small>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}
