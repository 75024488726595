import { useParams } from 'react-router-dom'

import { Layout } from '../../../../../components/Layout'
import { ReactionRoleDetail } from '../../../../../components/Guilds/GuildDetail/ReactionRoles/Detail'

export const ReactionRoleDetailView = () => {
  const { guildId, reactionRoleId } = useParams()

  return (
    <Layout>
      <ReactionRoleDetail guildId={guildId!} reactionRoleId={reactionRoleId!} />
    </Layout>
  )
}
