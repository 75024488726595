import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import type { User } from '../../models/User'

interface Props {
  onClick: () => void
  currentUser?: User
  loading: boolean
}

export const LoginView = ({ onClick, currentUser, loading }: Props) => {
  return currentUser ? (
    <div>'Logged in'</div>
  ) : (
    <Button onClick={onClick} disabled={loading}>
      {loading ? (
        <div>
          Logging in... <Spinner animation="border" size="sm" />
        </div>
      ) : (
        'Login with Discord'
      )}
    </Button>
  )
}
