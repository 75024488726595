import { Layout } from '../../components/Layout'
import { Settings } from '../../components/Settings'

import { PageHeaderView as PageHeader } from '../../components/PageHeader'

export const SettingsView = () => {
  return (
    <Layout>
      <PageHeader>Settings</PageHeader>
      <Settings />
    </Layout>
  )
}
