import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import { useNavigate } from 'react-router-dom'

const titleByFeatureName: Record<string, string> = {
  dailyQuotes: 'Quote of the Day',
  leavingMessage: 'Leaving Message',
  notifications: 'Notifications',
  personalizedBot: 'Personalized Bot',
  permissions: 'Permissions',
  polls: 'Polls',
  reactionRoles: 'Reaction Roles',
  tiktokIntegration: 'TikTok Integration',
  twitchIntegration: 'Twitch Integration',
  welcomeMessage: 'Welcome Message',
  youtubeIntegration: 'YouTube Integration'
}

const descriptionByFeatureName: Record<string, string> = {
  dailyQuotes: 'Post an inspirational quote to a channel every day',
  leavingMessage: 'Post a customizable message to a channel when a member leaves the server',
  notifications: 'Post a customizable message to a channel or get a DM one time or on an interval',
  personalizedBot: 'Personalize your bot with a username, avatar, and status of your choice',
  permissions: 'Control which roles have permission to use which features',
  polls: 'Add a poll to the current channel by using the /poll command',
  reactionRoles: 'Allow users to self-assign roles by reacting to a message',
  tiktokIntegration: 'Integrate TikTok content directly into your Discord channels',
  twitchIntegration: 'Get notified of Twitch streams and updates within your Discord server',
  welcomeMessage: 'Greet new members with a personalized message',
  youtubeIntegration: 'Receive notifications for new YouTube uploads and live streams'
}

const linkByFeatureName: Record<string, string> = {
  dailyQuotes: 'quote-of-the-day',
  leavingMessage: 'leaving-message',
  notifications: 'notifications',
  personalizedBot: 'personalized-bot',
  permissions: 'permissions',
  polls: 'polls',
  reactionRoles: 'reaction-roles',
  tiktokIntegration: 'tiktok-integration',
  twitchIntegration: 'twitch-integration',
  welcomeMessage: 'welcome-message',
  youtubeIntegration: 'youtube-integration'
}

const linkTextByFeatureName: Record<string, string> = {
  dailyQuotes: 'View Quote of the Day',
  leavingMessage: 'View Leaving Message',
  notifications: 'View Notifications',
  personalizedBot: 'Personalize Bot',
  permissions: 'View Permissions',
  polls: 'View Polls',
  reactionRoles: 'View Reaction Roles',
  twitchIntegration: 'View Twitch Integration',
  welcomeMessage: 'View Welcome Message'
}

interface Props {
  feature: string
  guildId: string
}

export const FeatureCardView = ({ feature, guildId }: Props) => {
  const navigate = useNavigate()

  return (
    <Card>
      <Card.Body className="d-flex flex-column text-center" style={{ minHeight: '184px' }}>
        <Card.Title>{titleByFeatureName[feature]}</Card.Title>
        <Card.Text className="text-start mt-3">{descriptionByFeatureName[feature]}</Card.Text>
        <div className="mt-auto">
          {linkTextByFeatureName[feature] ? (
            <Button onClick={() => navigate(`/dashboard/${guildId}/${linkByFeatureName[feature]}`)}>{linkTextByFeatureName[feature]}</Button>
          ) : (
            <Button disabled>Coming Soon!</Button>
          )}
        </div>
      </Card.Body>
    </Card>
  )
}
