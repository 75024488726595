import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import type { User, UserPatch } from '../../../models/User'

import { EmailFormView as EmailForm } from './EmailFormView'

interface Props {
  setUpdateEmail: (updateEmail: boolean) => void
  user: User
  updateUser: (id: string, data: UserPatch) => void
}

export const RegisterOrUpdateEmailView = ({ setUpdateEmail, updateUser, user }: Props) => {
  const handleEmailSubmit = (data: { email: string }) => {
    updateUser(user.id, data)
    setUpdateEmail(false)
  }

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center mt-3">
      <Row className="w-100">
        <Col className="text-center">
          <h2>{user.emailVerified ? 'Update E-mail' : 'Register Your E-mail Address'}</h2>
        </Col>
      </Row>
      <Row className="w-100 mt-2">
        <Col md={6} ld={6} className="mx-auto">
          <EmailForm existingEmail={user.email} onSubmit={handleEmailSubmit} />
        </Col>
      </Row>
    </Container>
  )
}
