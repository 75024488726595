import type { Action, PayloadAction } from '@reduxjs/toolkit'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { PollVote } from '../../../models/PollVote'

import { Api } from '../../../lib/api'
import { mergeData, removeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: PollVote[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

const handlePending = (state: DataState) => {
  delete state.errorMessage
  state.loading = true
}

const handleRejection = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const findAsync = createAsyncThunk('poll-votes/find', async (params: Params, { dispatch }): Promise<PollVote[]> => {
  try {
    const { data } = await Api.find('poll-votes', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting poll votes: ${(e as Error).message}` }))
    throw e
  }
})

const pollVotesSlice = createSlice({
  name: 'poll-votes',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<PollVote | PollVote[]>) => {
      state.data = mergeData(state.data, action.payload)
    },
    dataRemoved: (state, action: PayloadAction<PollVote | PollVote[]>) => {
      state.data = removeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, handlePending)
      .addCase(findAsync.fulfilled, (state, action) => {
        state.data = mergeData(state.data, action.payload)
        state.loading = false
      })
      .addCase(findAsync.rejected, handleRejection)
  }
})

export const { dataAdded, dataRemoved } = pollVotesSlice.actions

export default pollVotesSlice.reducer
