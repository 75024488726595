import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import type { User, UserPatch } from '../../../models/User'

import { VerifyEmailFormView as VerifyEmailForm } from './VerifyEmailFormView'

interface Props {
  setUpdateEmail: (updateEmail: boolean) => void
  user: User
  updateUser: (id: string, data: UserPatch) => void
}

export const VerifyEmailView = ({ setUpdateEmail, updateUser, user }: Props) => {
  const handleVerifyEmailSubmit = (data: { emailVerificationCode: string }) => {
    updateUser(user.id, { ...data, email: user.email })
  }

  const resendCode = () => {
    updateUser(user.id, { email: user.email })
  }

  return (
    <Container className="d-flex flex-column justify-content-center align-items-center mt-3">
      <Row className="w-100">
        <Col className="text-center">
          <h2>{user.emailVerified ? 'Update Email' : 'A verification code has been sent to your e-mail'}</h2>
        </Col>
      </Row>
      <Row className="w-100 mt-2">
        <Col md={8} ld={8} className="mx-auto">
          <VerifyEmailForm onSubmit={handleVerifyEmailSubmit} resendCode={resendCode} setUpdateEmail={setUpdateEmail} />
        </Col>
      </Row>
    </Container>
  )
}
