import Container from 'react-bootstrap/Container'

import type { Guild } from '../../../../models/Guild'
import type { WelcomeMessage, WelcomeMessagePatch } from '../../../../models/WelcomeMessage'

import { PageHeaderView as PageHeader } from '../../../PageHeader/PageHeaderView'
import { Loading } from '../../../Loading/Loading'
import { NoBotInGuildView as NoBotInGuild } from '../../../Bots/NoBotInGuild/NoBotInGuildView'

import { WelcomeMessageFormView as WelcomeMessageForm } from './components/WelcomeMessageForm'

interface Props {
  editWelcomeMessage: (id: string, welcomeMessageData: WelcomeMessagePatch) => void
  loading: boolean

  guild?: Guild
  welcomeMessage?: WelcomeMessage
}

export const WelcomeMessageView = ({ editWelcomeMessage, loading, guild, welcomeMessage }: Props) => {
  return loading || !welcomeMessage ? (
    <Loading style={{ marginTop: '32vh' }} />
  ) : !guild?.hasBot ? (
    <NoBotInGuild guild={guild} />
  ) : (
    <Container>
      <PageHeader backButtonPath={`/dashboard/${guild?.id}`}>Welcome Message</PageHeader>

      <WelcomeMessageForm
        welcomeMessage={welcomeMessage}
        onSubmit={(welcomeMessageData: WelcomeMessagePatch) => editWelcomeMessage(welcomeMessage.id, welcomeMessageData)}
      />
    </Container>
  )
}
