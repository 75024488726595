import { connect } from 'react-redux'

import type { RootState } from '../../../redux/store'
import { selectBotsLoading, selectGuildData, selectGuildsLoading, selectPermissionsLoading } from '../../../redux/selectors'

import { isAuthorized } from '../../../lib/authorize'
import { PermissionNames } from '../../../lib/permissions'

import { GuildDetailView } from './GuildDetailView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state)

  const canEditPermissions = isAuthorized(PermissionNames.EDIT_PERMISSIONS, ownProps.guildId, loading, state)

  return {
    canEditPermissions,
    guild: selectGuildData(ownProps.guildId, state),
    loading
  }
}

export const GuildDetail = connect(mapStateToProps)(GuildDetailView)
