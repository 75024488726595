import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import {
  selectBotsLoading,
  selectCurrentThemeType,
  selectGuildData,
  selectGuildsLoading,
  selectPermissionsDataForGuild,
  selectPermissionsLoading
} from '../../../../redux/selectors'
import type { PermissionPatch } from '../../../../models/Permission'
import { patchAsync } from '../../../../redux/features/permissions'

import { isAuthorized } from '../../../../lib/authorize'
import { PermissionNames } from '../../../../lib/permissions'

import { PermissionsView } from './PermissionsView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state)
  const canEditPermissions = isAuthorized(PermissionNames.EDIT_PERMISSIONS, ownProps.guildId, loading, state)

  return {
    canEditPermissions,
    currentThemeType: selectCurrentThemeType(state),
    guild: selectGuildData(ownProps.guildId, state),
    loading,
    permissions: selectPermissionsDataForGuild(ownProps.guildId, state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updatePermission: async (id: string, data: PermissionPatch): Promise<void> => {
    dispatch(patchAsync({ id, data }))
  }
})

export const Permissions = connect(mapStateToProps, mapDispatchToProps)(PermissionsView)
