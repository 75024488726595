import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout/Layout'
import { QuoteOfTheDay } from '../../../../components/Guilds/GuildDetail/QuoteOfTheDay'

export const QuoteOfTheDayView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <QuoteOfTheDay guildId={guildId!} />
    </Layout>
  )
}
