import { BarChart, Bar, Label, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'

import type { PollOption } from '../../../../../../../models/PollOption'
import type { PollVote } from '../../../../../../../models/PollVote'

interface Props {
  barColor: string
  pollOptions: PollOption[]
  pollVotes: PollVote[]
}

export const BarGraphView = ({ barColor, pollOptions, pollVotes }: Props) => {
  const data = pollOptions.map((pollOption: PollOption) => {
    return {
      name: pollOption.text,
      votes: pollVotes.filter((pollVote: PollVote) => pollVote.optionId === pollOption.id).length
    }
  })

  return (
    <BarChart width={700} height={360} data={data} margin={{ top: 20, right: 30, left: 20, bottom: 80 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" angle={45} textAnchor="start" label={''} />
      <YAxis>
        <Label position="insideLeft" style={{ textAnchor: 'middle' }} content={<h5>Votes</h5>} />
      </YAxis>
      <Tooltip />
      <Bar dataKey="votes" fill={barColor} />
    </BarChart>
  )
}
