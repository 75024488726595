import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import { selectBotsLoading, selectGuildData, selectGuildsLoading, selectPermissionsLoading } from '../../../../redux/selectors'

import { createAsync, patchAsync } from '../../../../redux/features/leavingMessages'

import type { LeavingMessageData, LeavingMessagePatch } from '../../../../models/LeavingMessage'

import { LeavingMessageView } from './LeavingMessageView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  guild: selectGuildData(ownProps.guildId, state),
  loading: selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state)
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  createLeavingMessage: (data: LeavingMessageData) => {
    dispatch(createAsync({ data }))
  },
  updateLeavingMessage: (id: string, data: LeavingMessagePatch) => {
    dispatch(patchAsync({ id, data }))
  }
})

export const LeavingMessage = connect(mapStateToProps, mapDispatchToProps)(LeavingMessageView)
