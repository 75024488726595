import { useParams } from 'react-router-dom'

import { Layout } from '../../../../components/Layout'
import { PersonalizedBot } from '../../../../components/Guilds/GuildDetail/PersonalizedBot'

export const PersonalizedBotView = () => {
  const { guildId } = useParams()

  return (
    <Layout>
      <PersonalizedBot guildId={guildId!} />
    </Layout>
  )
}
