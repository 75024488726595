const hostname = window.location.hostname

export const environment = hostname === 'denisonweb-local-development.com' ? 'development' : hostname === 'staging.rt.ninja' ? 'staging' : 'production'

export const url =
  environment === 'development'
    ? 'https://denisonweb-local-development.com:3002/'
    : environment === 'staging'
      ? 'https://api-staging.rt.ninja/'
      : 'https://api.rt.ninja/'
