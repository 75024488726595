import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import {
  selectBotAuthorizing,
  selectBotsLoading,
  selectBotDataByGuildId,
  selectGuildData,
  selectGuildsLoading,
  selectPermissionsLoading
} from '../../../../redux/selectors'
import { patchAsync as patchBotAsync, setAuthorizing } from '../../../../redux/features/bots/botsSlice'

import type { BotPatch } from '../../../../models/Bot'
import { openPersonalizedDiscordBotOauthUrl } from '../../../../lib/util'
import { SyncManager } from '../../../../lib/feathers/lib/syncManager'

import { PersonalizedBotView } from './PersonalizedBotView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  authorizing: selectBotAuthorizing(state),
  bot: selectBotDataByGuildId(ownProps.guildId, state),
  guild: selectGuildData(ownProps.guildId, state),
  loading: selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state)
})

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  updateBot:
    (id: string) =>
    async (data: BotPatch): Promise<void> => {
      const result = await dispatch(patchBotAsync({ id, data }))
      const clientId = (result.payload as { personalizedBotTokenClientId?: string })?.personalizedBotTokenClientId
      if (clientId) {
        dispatch(setAuthorizing(true))
        SyncManager.setDiscordPersonalizedBotOauthWindow(openPersonalizedDiscordBotOauthUrl(ownProps.guildId, clientId), () => dispatch(setAuthorizing(false)))
      }
    }
})

export const PersonalizedBot = connect(mapStateToProps, mapDispatchToProps)(PersonalizedBotView)
