import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../redux/store'
import {
  selectBotsLoading,
  selectGuildData,
  selectGuildsLoading,
  selectPermissionsLoading,
  selectPollsDataForGuild,
  selectPollsLoading
} from '../../../../redux/selectors'
import { findAsync as findPollsAsync } from '../../../../redux/features/polls'

import { isAuthorized } from '../../../../lib/authorize'
import { PermissionNames } from '../../../../lib/permissions'

import { PollsView } from './PollsView'

interface OwnProps {
  guildId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const loading = selectBotsLoading(state) || selectGuildsLoading(state) || selectPermissionsLoading(state) || selectPollsLoading(state)

  const canCreate = isAuthorized(PermissionNames.CREATE_POLLS, ownProps.guildId, loading, state)

  return {
    canCreate,
    guild: selectGuildData(ownProps.guildId, state),
    polls: selectPollsDataForGuild(ownProps.guildId, state),
    loading
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  load: (): void => {
    dispatch(findPollsAsync({ query: { guildId: ownProps.guildId } }))
  }
})

export const Polls = connect(mapStateToProps, mapDispatchToProps)(PollsView)
