import { createAsyncThunk, createSlice, type Action, type PayloadAction } from '@reduxjs/toolkit'
import type { Params } from '@feathersjs/feathers'

import type { LeavingMessage, LeavingMessageData, LeavingMessagePatch } from '../../../models/LeavingMessage'

import { Api } from '../../../lib/api'
import { mergeData } from '../../../lib/util'

import { createToast } from '../toasts/toastsSlice'

interface DataState {
  loading: boolean
  data: LeavingMessage[]
  errorMessage?: string
}

const initialState: DataState = {
  loading: false,
  data: []
}

type ICreateParams = {
  data: LeavingMessageData
  params?: Params
}

interface IPatchParams {
  id: string
  data: LeavingMessagePatch
  params?: Params
}

export const findAsync = createAsyncThunk('leavingMessages/find', async (params: Params | undefined, { dispatch }): Promise<LeavingMessage[]> => {
  try {
    const { data } = await Api.find('leaving-messages', params)
    return data
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error getting leavingMessages: ${(e as Error).message}` }))
    throw e
  }
})

export const createAsync = createAsyncThunk('leavingMessages/create', async ({ data, params }: ICreateParams, { dispatch }): Promise<LeavingMessage | void> => {
  try {
    return await Api.create('leaving-messages', data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: `Error creating leavingMessage: ${(e as Error).message}` }))
    throw e
  }
})

export const patchAsync = createAsyncThunk('leavingMessages/patch', async ({ id, data, params }: IPatchParams, { dispatch }): Promise<LeavingMessage> => {
  try {
    return await Api.patch('leaving-messages', id, data, params)
  } catch (e: unknown) {
    dispatch(createToast({ type: 'error', message: (e as Error).message }))
    throw e
  }
})

const handleFulfilled = (state: DataState, action: PayloadAction<LeavingMessage | LeavingMessage[]>) => {
  if (action.payload) {
    state.data = mergeData(state.data, action.payload)
  }
  state.loading = false
}

const handleRejected = (state: DataState, action: Action<string> & { error: { message?: string } }) => {
  state.errorMessage = action.error.message
  state.loading = false
}

export const leavingMessagesSlice = createSlice({
  name: 'leavingMessages',
  initialState,
  reducers: {
    dataAdded: (state, action: PayloadAction<LeavingMessage | LeavingMessage[]>) => {
      state.data = mergeData(state.data, action.payload)
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(findAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(findAsync.fulfilled, handleFulfilled)
      .addCase(findAsync.rejected, handleRejected)
      .addCase(patchAsync.pending, (state) => {
        delete state.errorMessage
        state.loading = true
      })
      .addCase(patchAsync.fulfilled, handleFulfilled)
      .addCase(patchAsync.rejected, handleRejected)
  }
})

export const { dataAdded } = leavingMessagesSlice.actions

export default leavingMessagesSlice.reducer
