import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../redux/store'

import { selectCurrentUser, selectCurrentTheme, selectUsersLoading } from '../../redux/selectors'
import { SettingsView } from './SettingsView'

const mapStateToProps = (state: RootState) => {
  return {
    loading: selectUsersLoading(state),
    currentUser: selectCurrentUser(state),
    currentTheme: selectCurrentTheme(state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({})

export const Settings = connect(mapStateToProps, mapDispatchToProps)(SettingsView)
