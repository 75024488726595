import { Dropdown } from 'react-bootstrap'

import type { Channel } from '../../models/Guild'

interface Props {
  onChannelClick: (channel: Channel) => void

  buttonText?: string
  channels?: Channel[]
}

export const ChannelPickerView = ({ buttonText = 'Select Channel', channels = [], onChannelClick }: Props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="success">{buttonText}</Dropdown.Toggle>

      <Dropdown.Menu>
        {channels.map((channel) => (
          <Dropdown.Item key={channel.id} onClick={() => onChannelClick(channel)}>
            {channel.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}
