import { connect } from 'react-redux'

import type { AppDispatch, RootState } from '../../../../../redux/store'
import {
  selectGuildData,
  selectGuildsLoading,
  selectReactionRoleData,
  selectReactionRoleOptionsDataForReactionRole,
  selectReactionRolesLoading,
  selectReactionRoleOptionsLoading
} from '../../../../../redux/selectors'
import {
  findAsync as findReactionRolesAsync,
  removeAsync as removeReactionRoleAsync,
  patchAsync as patchReactionRoleAsync
} from '../../../../../redux/features/reactionRoles/reactionRolesSlice'
import { findAsync as findReactionRoleOptionsAsync } from '../../../../../redux/features/reactionRoleOptions/reactionRoleOptionsSlice'
import { closeAll, open } from '../../../../../redux/features/modal/modalSlice'
import type { ReactionRolePatch } from '../../../../../models/ReactionRole'

import { PermissionNames } from '../../../../../lib/permissions'
import { isAuthorized } from '../../../../../lib/authorize'

import { ReactionRoleDetailView } from './ReactionRoleDetailView'

interface OwnProps {
  guildId: string
  reactionRoleId: string
}

const mapStateToProps = (state: RootState, ownProps: OwnProps) => {
  const reactionRole = selectReactionRoleData(ownProps.reactionRoleId, state)
  const loading = selectGuildsLoading(state) || selectReactionRolesLoading(state) || selectReactionRoleOptionsLoading(state)

  const canRemoveReactionRole = isAuthorized(PermissionNames.MANAGE_REACTION_ROLES, ownProps.guildId, loading, state)

  return {
    canRemoveReactionRole,
    guild: selectGuildData(ownProps.guildId, state),
    loading,
    reactionRole,
    reactionRoleOptions: selectReactionRoleOptionsDataForReactionRole(ownProps.reactionRoleId, state)
  }
}

const mapDispatchToProps = (dispatch: AppDispatch, ownProps: OwnProps) => ({
  load: (): void => {
    dispatch(findReactionRolesAsync({ query: { id: ownProps.reactionRoleId } }))
    dispatch(findReactionRoleOptionsAsync({ query: { reactionRoleId: ownProps.reactionRoleId } }))
  },
  openModal: (modalName: string): void => {
    dispatch(open(modalName))
  },
  patchReactionRole: (data: ReactionRolePatch): void => {
    dispatch(patchReactionRoleAsync({ id: ownProps.reactionRoleId, data }))
  },
  removeReactionRole: async (): Promise<void> => {
    dispatch(closeAll())
    await dispatch(removeReactionRoleAsync(ownProps.reactionRoleId))
  }
})

export const ReactionRoleDetail = connect(mapStateToProps, mapDispatchToProps)(ReactionRoleDetailView)
