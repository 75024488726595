import { SubscriptionDurations } from '../../lib/subscription'

export const durationOptions: Record<string, string> = {
  '1 Month': SubscriptionDurations.ONE_MONTH,
  '3 Months (5% discount)': SubscriptionDurations.THREE_MONTHS,
  '6 Months (10% discount)': SubscriptionDurations.SIX_MONTHS,
  '1 Year (25% discount)': SubscriptionDurations.TWELVE_MONTHS
}

export type DurationOptionType = (typeof durationOptions)[keyof typeof durationOptions]
